

import React, { useContext, useEffect, useState } from 'react'
import FormInput from '../../components/form/forminput.component'
import FormTextarea from '../../components/form/textarea.component'
import { TbCurrentLocation, TbPhone, TbUser, TbUserEdit } from 'react-icons/tb'
import { Button, Option, Select } from '@material-tailwind/react'
import FormSelect from '../../components/form/formSelect.component'
import { MdOutlineFileUpload } from 'react-icons/md'
import { PiInstagramLogo, PiTiktokLogoThin } from 'react-icons/pi'
import { FaSquareTwitter } from 'react-icons/fa6'
import { FaSave, FaSnapchatSquare } from 'react-icons/fa'
import { SiInstagram } from 'react-icons/si'
import { LuSubtitles } from 'react-icons/lu'
import { BsSave, BsSnapchat } from 'react-icons/bs'
import { SlSocialTwitter } from "react-icons/sl";
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { errorToast, imageDb, infoToast } from '../../utils/firebase/firebase.utils'
import { EventsContext } from '../../context/events.context'
import { v4 } from 'uuid';
import { processSecs } from './new_event.components'
import useAuth from '../../hooks/useAuth'


const NewInfluencerForm = ({ send }) => {
  // console.log('Start')
  
  const { currentUser } = useAuth()
  const { influencer, addInfluencer, updateInfluencer } = useContext(EventsContext)

  var defaultValues = {
    fname: '', 
    sname: '', 
    phone: '', 
    ig: '', 
    snap: '', 
    tx: '', 
    tk: '',
    dp: '',
    del: 'no',
    dp_msg: '',
    status: 'active',
    secs: '',
    user: currentUser?.uid,
    created_at: new Date,
    updated_at: '',
  }
  const [formFields, setFormFields] = useState(defaultValues)
  const [img, setImg] = useState('')
  const [imgUrl, setImgUrl] = useState([])

  
  const handlePhotoUpload = (event) =>{
    if(img !== ''){
      var sendUrl
      const imgRef = ref(imageDb,`files/influencers/${v4()}`)
      uploadBytes(imgRef,img).then(val=>{
        // console.log(val)
        getDownloadURL(val.ref).then(url=>{
          setImgUrl(data=>[...data,url])
          formFields['dp'] = url
          // console.log('URL = '+formFields['dp'])
          handleAddInfluencer(event, url)
        })
      })
    }else{
      return infoToast('Oops..! Select event Photo to proceed')
    }
  }

  const handlePhotoUpload2 = (event) =>{
    if(img !== ''){
      const imgRef = ref(imageDb,`files/influencers/${v4()}`)
      uploadBytes(imgRef,img).then(val=>{
        // console.log(val)
        getDownloadURL(val.ref).then(url=>{
          setImgUrl(data=>[...data,url])
          formFields['dp'] = url
          handleInfluencerUpdate(event, url)
        })
      })
    }else{
      if (send['dp'] == '' && send['status'] == 'active') {
        return infoToast('Oops..! Photo cannot be empty')
      }
      handleInfluencerUpdate(event, send['dp'])
    }
    return
  }

  const handleOnchange = (event) => {
    const { name, value } = event.target;
    setFormFields({...formFields, [name]: value})
    console.log(value) 
  }

  const handleAddInfluencer = async (event, getUrl) => {
    event.preventDefault();
    if (!formFields['fname'] || !formFields['phone'] || !img) {
      return errorToast('Oops..! Fill in required fields')
    }

    // handlePhotoUpload()
    // console.log(imgUrl)
    formFields['dp'] = getUrl
    formFields['newUrl'] = getUrl
    // console.log(formFields)
    // const secs = processSecs(formFields.created_at.seconds)
    // formFields['secs'] = secs;
    // console.log(formFields.created_at)
    
    await addInfluencer(formFields)
    setFormFields(defaultValues)
    // console.log(secs);
  }

  const handleInfluencerUpdate = (event, getUrl) => {
    event.preventDefault();

    if (send) {
      setImg(send['dp']) 
      console.log('imgUrl sel')
      console.log(img)
      console.log(imgUrl)
    }
    formFields['dp'] = getUrl
    formFields['newUrl'] = getUrl
    // return console.log(formFields)
    const toastMsg = 'Update successful';
    setFormFields(defaultValues)
    updateInfluencer(formFields, toastMsg)
  }

  useEffect(() => {
    if (send) {
      setFormFields(send);
    }
  }, []);


  return (
    <>
    <form className="gen-form">
        <FormInput name='fname' type='text' size='md' label='Firstname' className='m-2' inIcon={<TbUserEdit />} onChange={handleOnchange} value={formFields.fname} required/>
        <FormInput name='sname' type='text' size='md' label='Last Name' className='m-2' inIcon={<TbUserEdit />} onChange={handleOnchange} value={formFields.sname} />
        <FormInput name='phone' type='number' size='md' label='Phone' min='0' className='m-2' inIcon={<TbPhone />} onChange={handleOnchange} value={formFields.phone} required/>
                    
        <div className='social-links-container'>
          <p className='blue-text'>Add social links here</p>
          <FormInput name='ig' type='text' size='md' label='Instagram' className='m-2' inIcon={<PiInstagramLogo />} onChange={handleOnchange} value={formFields.ig} />
          <FormInput name='snap' type='text' size='md' label='Snapchat' className='m-2' inIcon={<BsSnapchat />} onChange={handleOnchange} value={formFields.snap} />
          <FormInput name='tx' type='text' size='md' label='Twitter' className='m-2' inIcon={<SlSocialTwitter />} onChange={handleOnchange} value={formFields.tx} />
          <FormInput name='tk' type='text' size='md' label='Tiktok' className='m-2' inIcon={<PiTiktokLogoThin />} onChange={handleOnchange} value={formFields.tk} />
        </div>

        <FormTextarea name='dp_msg' type='text' maxLength='50' size='md' label='Display Message' className='m-2' onChange={handleOnchange} value={formFields.dp_msg} />
        {/* <FormSelect name='pub_ticket' opt_type='boolean' label='Publish Ticket' className='m-2'></FormSelect> */}
        <FormInput onChange={(e)=>setImg(e.target.files[0])} name='dp' type='file' size='md' label='Upload Display Photo' className='m-2' inIcon={<MdOutlineFileUpload />} required/>

        { send ? 
          <div className='w-16 mx-4 my-1 float-left'>
            <img className='rounded-md' src={send.dp}/>
          </div>
        :null}

        <Button onClick={send ? handlePhotoUpload2 : handlePhotoUpload} className='float-right mx-2 my-4' variant='gradient' color='green'>
          {send ?
          <>Update &nbsp; <FaSave size={14} className='float-right' /></>
          :<>Submit &nbsp; <BsSave size={14} className='float-right' /></>
          }
          </Button>
        <p className='clear-both'>&nbsp;</p>

        {/* <Button variant="gradient" color="green">
            <span>Confirm</span>
          </Button> */}

    </form>
    </>
  )
}

export default NewInfluencerForm