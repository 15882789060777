

import React, { useContext, useEffect, useState } from 'react'
import { EventsContext } from '../../context/events.context'

const TrialCheck = () => {

    const { events } = useContext(EventsContext)
    const [ eventsHolder, setEventHolder ] = useState(events)


    useEffect(() => {
        setEventHolder(events)
        console.log('events :')
        console.log(events)
        console.log('events holder :')
        console.log(eventsHolder)
    }, [events])

    useEffect(() => {
        console.log('new events holder :')
        console.log(eventsHolder)
    }, [eventsHolder])

  return (
    <>
        <div>TrialCheck</div>
        {
            eventsHolder ?
                eventsHolder.map((evt, x) => (
                    <p key={x}>{x} - {evt.title}</p>
                ))
            :null
        }
    </>
  )
}

export default TrialCheck