import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
    Avatar,
} from "@material-tailwind/react";
import './inv-display.styles.scss'
import '../../routes/other-styles.styles.css'
import { LuCalendarDays, LuMap, LuMapPin } from "react-icons/lu";
import { FaRegCalendarDays, FaSquareSnapchat, FaSquareTwitter, FaTiktok } from "react-icons/fa6";
import { CiBullhorn } from "react-icons/ci";
import { RiInstagramFill } from "react-icons/ri";
import { GrInstagram } from "react-icons/gr";
import { SiInstagram } from "react-icons/si";
import { FaSnapchatSquare } from "react-icons/fa";
import { PiTiktokLogo, PiTiktokLogoThin } from "react-icons/pi";
import { VscSend } from "react-icons/vsc";
import { BsSend } from "react-icons/bs";
import { StickyNavbar } from "../../components/navbar/navbar.components";
import Dialog01 from "../../components/dialog/dialog.components";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { EventsContext } from "../../context/events.context";
import { newDate, processSecs } from "../admin/new_event.components";
import { infoToast } from "../../utils/firebase/firebase.utils";

   
  const InvDisplay = () => {
    const { key } = useParams();
    const navigate = useNavigate()
    const { events, getEvents } = useContext(EventsContext)
    const [ dialogState, setDialogState ] = useState('');

    
    useEffect(() => {
        if (events) {
            setTimeout(() => {
                setDialogState('open')
            }, 5000);
        }
    },[]);


    const event = events.find(ev => ev.id === key);
    if (event) {
        if (event.status != 'active') {
            infoToast('Event Inactive')
            console.log('Event Inactive')
            navigate('/events')
        }
    // }else{
    //     return null;
    }
    
    // const sec = processSecs('29-08-2024')
    // console.log(sec)

    // useEffect(() => {
    //     console.log(event)
    // },[events]);




    return (
        <>
        { event ? 
            <Card className="mt-6 w-full text-center">
                <CardHeader color="blue-gray" className="relative">
                <img className="w-full"
                    src={event.flyer}
                    // src="https://psdfreebies.com/wp-content/uploads/2021/11/Weekend-Club-Party-Flyer-PSD-Template-Preview-730x1024.jpg"
                    alt="flyer"
                />
                </CardHeader>


                <CardFooter className="p-0 my-7" color="transparent">
                    {/* {event.ussd != null ? <p className="text-xs m-2 text-gray-500 font-light">Dial <span className="text-black text-md font-medium">{event.ussd}</span> to buy tickets</p>:null} */}
                    { event.ticket != null ?
                    <>
                        <p className="text-xs m-2 text-gray-500 font-light">Click on the link below {event.ussd != null ? <span>or dial <b className="text-black text-md font-medium">{event.ussd}</b></span>:null} to buy tickets</p>
                        <a href={event.ticket}><Button>Get Tickect &nbsp; &nbsp; <span className="float-right"><BsSend /></span></Button></a><br />
                        {/* <Link to={event.rate != 'ticketed' ? `/tickets/${event.id}`: event.ticket}><Button>Get Tickect &nbsp; &nbsp; <span className="float-right"><BsSend /></span></Button></Link><br /> */}
                    </>
                    :null}
                    <Button size="sm" variant="outlined" className="m-2 hover:bg-gray-100" onClick={() => setDialogState(Math.random())}>&nbsp;&nbsp;RSVP&nbsp;&nbsp;</Button>
                </CardFooter>

                <Dialog01 declareState={dialogState} curEvent={event} body='ATD' head='Can you attend?' />

                <CardBody className="cardbody-with-bg">
                    <Typography variant="h5" color="blue-gray" className="mb-2 capitalize">
                        {event.title}
                    </Typography>
                    <p className="host">Host: <span>{event.host}</span></p>

                    <div className="pop">
                        <div className="pop-left">
                            <LuCalendarDays size='24' className="pop-icon" />
                        </div>
                        
                        <div className="pop-right">
                            <h2>{newDate(event.secs)}</h2>
                            { event.start_at ? <p>{event.start_at} Prompt</p> : <p>&nbsp;</p>} 
                            {/* <p>Click to add to your calendar</p> */}
                        </div>
                    </div>

                    <a href={event?.mapurl}>
                        <div className="pop">
                            <div className="pop-left">
                                <LuMapPin size='24' className="pop-icon" />
                            </div>
                            
                            <div className="pop-right">
                                <h2>{event.venue}</h2>
                                <p>Click to follow map</p>
                            </div>
                        </div>
                    </a>

                </CardBody>
                
                <div className="host-container" style={{background: `url(${event.flyer})`}}>
                    <div className="host-msg">
                        <h2><CiBullhorn size={28} className="float-left mr-4 -rotate-12" /> Message from <span>{event.host}</span></h2>
                        <h4 className="line"></h4>
                        <p>{event.host_msg}</p>
                        <div className="flex gap-2 mx-5 mt-10 mb-5">
                            { event.ig ? <Link to={event.ig}><SiInstagram className="social-icon" size={24} /></Link>  :null}
                            { event.snap ? <Link to={event.snap}><FaSnapchatSquare className="social-icon" size={25} /></Link> :null}
                            { event.tx ? <Link to={event.tx}><FaSquareTwitter className="social-icon" size={25} /></Link> :null}
                            { event.tk ? <Link to={event.tk}><PiTiktokLogo className="social-icon" size={26} /></Link> :null}
                        </div>
                    </div>
                </div>
                
                <div className="pivoapps-footer">
                    <p>Copyright 2023 Pivoapps&nbsp;
                        <img className="float-right" src="https://firebasestorage.googleapis.com/v0/b/sproude-pos.appspot.com/o/files%2Fec1084bc-1792-4b0a-85ad-feb6a35f7f60?alt=media&token=72532b5b-b67e-4e07-968a-b2a7a41eef61" alt="" />
                    </p>
                </div>
                
            </Card>
        :<div className="oops1">
            <p>Oops..! No Event Found</p>
            <Link to='/events'><Button variant="gradient" color="green" size="sm">Back</Button></Link>
        </div>
        }
        </>
    );
  }

  export default InvDisplay