
import React, { useContext, useEffect, useState } from 'react'
import './admin.styles.scss'
import '../../routes/other-styles.styles.css'
import { Button, Card, CardBody, Tooltip } from '@material-tailwind/react'
import NewEventForm from './new-event-form.components'
import Dialog01 from '../../components/dialog/dialog.components';
import { GrEdit } from 'react-icons/gr';
import { BsPlusCircle } from 'react-icons/bs';
import { LuFileEdit } from 'react-icons/lu'
import { TbUserCog, TbUserEdit, TbUserPlus } from 'react-icons/tb'
import { GoTrash } from "react-icons/go";
import { EventsContext } from '../../context/events.context'

const NewInfluencer = () => {
    
    const { influencers } = useContext(EventsContext)
    const [ influs, setInflus ] = useState(influencers)
    const [ dialogState, setDialogState ] = useState('');
    const [ sendToDialog, setSendToDialog ] = useState([])
    const handleNewEvent = () => {}
    

    const processSend = (el) => {
        console.log(el)
        setSendToDialog(el)
        setDialogState(Math.random())
    }

    const handleAddInfluencer = () => {
        setSendToDialog()
        setDialogState(Math.random())
    }

    useEffect(() => {
        setInflus(influencers)
        // console.log('---')
        // console.log(influs)
        // console.log('---')
    },[influencers])

  return (
    <Card className='px-2 py-5 m-3'>
        <Button size='sm' variant='gradient' color='red' onClick={handleAddInfluencer} className='w-44 mb-4'>
            <span className="float-left"><TbUserPlus size={16} /></span>&nbsp; Add Influencer
        </Button>
        {sendToDialog ? 
        <Dialog01 declareState={dialogState} send={sendToDialog} body='influencer' icon={<TbUserEdit />} head='Update Influencer' />
        :<Dialog01 declareState={dialogState} body='influencer' icon={<TbUserPlus />} head='Add Influencer' />
        }
        
        { influs ? influs.map(el => 
            <div key={Math.random()} className='event-row-container'>
                <div className='ev-flyer-col p-2'>
                    <img alt=""
                        src={el.dp}
                        className="relative inline-block object-cover object-center w-12 h-12 rounded-full cursor-pointer"
                        // data-popover-target="profile-menu" 
                    />
                </div>

                <div className='ev-details'>
                    <h1>{el.fname+' '+el.sname}</h1>
                    <h2>{el.phone}</h2>
                </div>

                <div className='ev-actions'>
                    <Tooltip content='Edit' className='tooltip-style'><button className='action-btn' onClick={() => processSend(el)}><LuFileEdit size='12'/></button></Tooltip>
                </div>
            </div>
        ) 
        :null}

        {/* <div className='event-row-container'>
            <div className='ev-flyer-col p-2'>
                <img alt=""
                    src="https://images.squarespace-cdn.com/content/v1/567f25e4a976af5d461070fe/1537118855595-NL5HKFW0Y72Z2XN8F240/DeeRees_forumsquare.jpg"
                    className="relative inline-block object-cover object-center w-12 h-12 rounded-full cursor-pointer"
                    // data-popover-target="profile-menu" 
                />
            </div>

            <div className='ev-details'>
                <h1>Loyal Nana</h1>
                <h2>+7156312214123</h2>
            </div>

            <div className='ev-actions'>
                <Tooltip content='Edit' className='tooltip-style'><button className='action-btn'><LuFileEdit size='12'/></button></Tooltip>
            </div>
        </div>
        
        <div className='event-row-container'>
            <div className='ev-flyer-col p-2'>
                <img alt=""
                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1480&amp;q=80"
                    className="relative inline-block object-cover object-center w-12 h-12 rounded-full cursor-pointer"
                    // data-popover-target="profile-menu" 
                />
            </div>

            <div className='ev-details'>
                <h1>Patrick Ola Yawson</h1>
                <h2>+174325487774</h2>
            </div>

            <div className='ev-actions'>
                <Tooltip content='Edit' className='tooltip-style'><button className='action-btn'><LuFileEdit size='12'/></button></Tooltip>
            </div>
        </div> */}
            
    </Card>
  )
}

export default NewInfluencer