
import React, { useContext, useEffect, useRef, useState } from 'react'
import './admin.styles.scss'
import '../../routes/other-styles.styles.css'
import { Button, Card, CardBody, Option, Popover, PopoverContent, PopoverHandler, Select, Tooltip } from '@material-tailwind/react'
import NewEventForm from './new-event-form.components'
import Dialog01 from '../../components/dialog/dialog.components';
import { GrEdit } from 'react-icons/gr';
import { BsPlusCircle } from 'react-icons/bs';
import { LuFileEdit } from 'react-icons/lu'
import { TbPhone, TbSearch, TbSend, TbUserCog, TbUserEdit, TbUserPlus, TbUserSearch } from 'react-icons/tb'
import { BiSolidCircle } from "react-icons/bi";
import FormInput from '../../components/form/forminput.component'
import FormTextarea from '../../components/form/textarea.component'
// import { Editor } from 'react-draft-wysiwyg';
// import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from "@tinymce/tinymce-react";
import { ContactsData } from '../../testData'
import { FaTimes } from 'react-icons/fa'
import { FaPlus } from 'react-icons/fa6'
import { IoSaveOutline } from "react-icons/io5";
import { MdOutlineRefresh, MdOutlineSave } from 'react-icons/md'
import { apiUrl, errorToast, infoToast, successToast, updateCompanyDoc } from '../../utils/firebase/firebase.utils'
import axios from 'axios'
import TxtEditor from '../../components/txtEditor.component'
import md5 from "md5";

import { useQuill } from 'react-quilljs';
// or const { useQuill } = require('react-quilljs');
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import useAuth from '../../hooks/useAuth'
import { UserContext } from '../../context/user.context'
import { PiWarningOctagonBold } from 'react-icons/pi'
import { EventsContext } from '../../context/events.context'

const MsgBroadcast = () => {
    // console.log('Started..!')

    const editorRef = useRef(null);
    const getTinyText = () => {
        var tinyVal = ''
        if (editorRef.current) {
            tinyVal = editorRef.current.getContent();
            // console.log(tinyVal);
        }
        return tinyVal;
    };

    const { quill, quillRef } = useQuill();
    const [ editorValue, setEditorValue ] = useState("Type Email Text Here..!");
    const [ smsText, setSmsText ] = useState("");
    const [ refresh, setRefresh ] = useState(false);
    const [ contacts, setContacts ] = useState();
    const [ showSearch, setShowSearch ] = useState(false);
    const [ newSearchKey, setNewSearchKey ] = useState('');
    const [ searchResult, setSearchResult ] = useState([]);
    const [ addedContacts, setAddedContacts ] = useState([]);
    const [ senderId, setSenderId ] = useState('Soho Friday');
    const NewSenderId = (x) => {
        // setSenderId(x)
        console.log('senderId: ', 'x')
    };
    const { events, getEvents, updateEvent } = useContext(EventsContext)
    const [ eventsRecords, setEventsRecords ] = useState(events)
    
    const [open, setOpen] = useState(true);
    const handleOpen = () => setOpen(!open);

    const { senderIDs, users, company } = useContext(UserContext);
    const [ curSenders, setCurSenders ] = useState([])
    const [ allUsers, setAllUsers ] = useState(users)
    const [ broadcastFlyer, setBroadcastFlyer ] = useState('Null')
    const [ smsCount, setSmsCount ] = useState(company?.sms_used)

    const [ dialogState, setDialogState ] = useState('');


    const handleSearch = (event) => {
        const { name, value } = event.target;

            setNewSearchKey(value.toLowerCase());

            if (value != '') {
                // var results = contacts.filter(el => el.fname?.toLowerCase().includes(value.toLowerCase()));
                var results = contacts.filter(el => el.fname?.toLowerCase().includes(value.toLowerCase()) || el.sname?.toLowerCase().includes(value.toLowerCase()) || el.phone?.toLowerCase().includes(value.toLowerCase()));
                // if (results.length > 0) {
                    setSearchResult(results);
                    // console.log(results.length)
                // }
                    // console.log('A')
            } else {
                // console.log('B')
                setNewSearchKey(value);
                setSearchResult([]);
            }
            
    }

    const searchContact = (e) => {
        const { name, value } = e.target;
        const txt = value;
        // console.log(txt)
        setNewSearchKey(txt)

        if (contacts) {
            if (txt != '') {
                const found = contacts.filter(el => el.fname?.toLowerCase().includes(txt.toLowerCase()) || el.sname?.toLowerCase().includes(txt.toLowerCase()) || el.phone?.toLowerCase().includes(txt.toLowerCase()))
                setSearchResult(found) 
                // console.log(found)
            } else {
                // console.log('Not Found..!')
                setNewSearchKey(txt);
                setSearchResult([]);
            }
        }
    }

    const handleSearch2 = (e) => {
      const txt = e.target.value;
      // console.log(txt)
      setNewSearchKey(txt)
  
      if (searchResult) {
        if (txt != '') {
          const found = contacts.filter(el => el.fname?.toLowerCase().includes(txt.toLowerCase()) || el.sname?.toLowerCase().includes(txt.toLowerCase()) || el.phone?.toLowerCase().includes(txt.toLowerCase()))
          // console.log(found)
          setSearchResult(found)
        } else {
          setSearchResult(contacts)
        }
      }
    }

    const addContact = (el) => {
        var Arr = []
        // return console.log(el)
        
        if (addedContacts.length > 0) {
            // console.log(1)
            Arr = addedContacts
            const checkExist = addedContacts.find((item) => item.email === el.email)
            // console.log(checkExist)
            if (!checkExist) {
                // console.log('no')
                Arr.push(el)
                setAddedContacts(Arr)
                setRefresh(!refresh)
            }
            successToast(el.fname+"'s contact added")
        } else {
            // console.log('001')
            Arr.push(el)
            // console.log(Arr)
            setAddedContacts(Arr)
            // console.log(locAddContact);
        }
        // console.log(addedContacts.length)
    }

    const removeContact = (el) => {
        const newResult = addedContacts.filter(item => item.email != el.email)
        setAddedContacts(newResult)
        localStorage.setItem('addedContacts', JSON.stringify(newResult))
        successToast(el.fname+"'s contact removed from queue")
    }

    const addAllContacts = () => {
        setNewSearchKey('')
        setSearchResult([])
        setShowSearch(true)
        setAddedContacts(contacts)
        // console.log('SR: ', addedContacts)
        // console.log('AC: ', addedContacts)
        successToast('All contacts added to queue')
    }

    const saveContentToLoc = () => {
        // const getEmailText = getTinyText();
        const getEmailText = quill.root.innerHTML;
        // if (addedContacts.length > 0) {
            localStorage.setItem('addedContacts', JSON.stringify(addedContacts))
            successToast('Content Successfully Saved')
            localStorage.setItem('emailText', getEmailText)
            localStorage.setItem('smsText', smsText)
        // } else {
        //     infoToast('Oops..! Empty Contenty')
        // }
    }

    const refreshContacts = () => {
        if (window.confirm('Click ok to confirm refresh')) {
            setNewSearchKey('')
            setSearchResult([])
            setAddedContacts([])
            localStorage.removeItem('smsText')
            localStorage.removeItem('emailText')
            localStorage.removeItem('addedContacts')
            successToast('Refresh Successful')
            setEditorValue("Type Email Text Here..!")
            // setSmsText("Type SMS Here..!")
        }
    }

    // const sendEmailBroadcast2 = async (e) => {
    //     e.preventDefault()
    //     const emailTxt = quill.root.innerHTML;
    //     const ArrReturn = [];

    //     return infoToast('Oops..! Function temporarily disabled')

    //     if (addedContacts == '' || addedContacts == []) {
    //         return infoToast('Select contacts to forward Emails!')
    //     }else {
    //         if (emailTxt == '' || emailTxt == null) {
    //             infoToast('Oops..! Type message to broadcast via email')
    //             return
    //         }
    //         if (!window.confirm('Click Ok to proceed with Email broadcast')) {
    //             return
    //         }
    //     }

    //     ArrReturn['ex'] = 'Nothing here tho..!'
    //     ArrReturn['msg'] = addedContacts
    //     ArrReturn['new'] = emailTxt

    //     try {
    //         const fwdMail = await axios.put(apiUrl()+'/sendmail/nomail', ArrReturn)
    //         if (fwdMail) {
    //             console.log('Result: ', fwdMail.data.result)
    //             console.log('Value: ', fwdMail.data.value)
    //         } else {
    //             console.log('Mail Error: ', fwdMail.value)
    //         }
    //     } catch (error) {
    //         console.log('Error: ', error)
    //     }
    //     return console.log('ArrReturn', ArrReturn)

    //     // console.log(addedContacts.length)
    //     const filteredAddedContacts = addedContacts.filter(el => el.email != 'Empty')
    //     // return console.log(filteredAddedContacts.length)

    //     try {

    //         for (let i = 0; i < filteredAddedContacts.length; i++) {

    //             const el = filteredAddedContacts[i];

    //             if (el.subscription == 'yes' && el.del == 'no' && el.email != 'Empty' || el.email != '') {
    //                 el['new'] = emailTxt;
    //                 // const fwdMail = await axios.put(apiUrl()+'/sendmail/'+emailTxt, el)
    //                 const fwdMail = await axios.put(apiUrl()+'/sendmail/'+el.email, el)
    //                 if (fwdMail) {
    //                     console.log('Value: ', fwdMail.data.value)
    //                 } else {
    //                     console.log('Mail Error: ', fwdMail.message)
    //                 }
    //             }
    //         }
    //         successToast('Email broadcast successful')

    //     } catch (error) {
    //         // console.log('Email not sent to ', el.email)
    //         console.log('Error broadcasting emails: ', error)
    //     }
    // }

    // const sendEmailBroadcast3 = async (e) => {
    //     e.preventDefault()
    //     const emailTxt = quill.root.innerHTML;
    //     // return console.log('apiUrl: ', apiUrl())

    //     if (addedContacts == '' || addedContacts == []) {
    //         return infoToast('Select contacts to forward Emails!')
    //     }else {
    //         if (emailTxt == '' || emailTxt == null) {
    //             infoToast('Oops..! Type message to broadcast via email')
    //             return
    //         }
    //         if (!window.confirm('Click Ok to proceed with Email broadcast')) {
    //             return
    //         }
    //     }

        
    //     // const filteredAddedContacts = addedContacts.filter(el => el.email != 'Empty')
    //     // // const filteredAddedContacts = addedContacts.filter((el, index) => index > 350)
    //     // setAddedContacts(filteredAddedContacts)
    //     // return

    //     // console.log(addedContacts.length)
    //     const filteredAddedContacts = addedContacts.filter(el => el.email != 'Empty')
    //     // return console.log(filteredAddedContacts.length)

    //     try {

    //         var xEmail = '';
            
    //         for (let i = 0; i < filteredAddedContacts.length; i++) {

    //             const el = filteredAddedContacts[i];


    //             if (el.subscription == 'yes' && el.del == 'no' && el.email != 'Empty' || el.email != '') {

    //                 xEmail = xEmail + el.email + ',';

    //             //     el['new'] = emailTxt;
    //             //     // const fwdMail = await axios.put(apiUrl()+'/sendmail/'+emailTxt, el)
    //             //     const fwdMail = await axios.put(apiUrl()+'/sendmail/'+el.email, el)
    //             //     if (fwdMail) {
    //             //         console.log('Value: ', fwdMail.data.value)
    //             //     } else {
    //             //         console.log('Mail Error: ', fwdMail.message)
    //             //     }
    //             }
    //         }
    //         const sendr = {
    //             'emails': xEmail,
    //             'emailText': emailTxt
    //         };
    //         console.log(sendr)
            
    //         sendr['new'] = emailTxt;
    //         // const fwdMail = await axios.put(apiUrl()+'/sendmail/'+el.email, el)
    //         const fwdMail = await axios.put(apiUrl()+'/sendmail/nomail', addedContacts)
    //         if (fwdMail) {
    //             console.log('Result2: ', fwdMail.data.result)
    //             console.log('Value2: ', fwdMail.data.value)
    //         } else {
    //             console.log('Mail Error: ', fwdMail.message)
    //         }
                

    //         // console.log(xEmail)
    //         successToast('Email broadcast successful')

    //     } catch (error) {
    //         // console.log('Email not sent to ', el.email)
    //         console.log('Error broadcasting emails: ', error)
    //     }
    // }
    
    const sendEmailBroadcast = async (e) => {
        e.preventDefault()
        const emailTxt = quill.root.innerHTML;


        if (addedContacts == '' || addedContacts == []) {
            return infoToast('Select contacts to forward Emails!')
        }else {
            if (emailTxt == '' || emailTxt == null) {
                infoToast('Oops..! Type message to broadcast via email')
                return
            }
            if (!window.confirm('Click Ok to proceed with Email broadcast')) {
                return
            }
        }
        // return console.log(broadcastFlyer)

        const filteredAddedContacts = addedContacts.filter(el => el.email != 'Empty')

        var xEmail = '';
        const sendr2 = [];
        const fnames = [];
        try {
            if (addedContacts.length > 1) {
                // setDialogState(Math.random())
                infoToast('Keep page open until broadcast is complete')
            }

            for (let i = 0; i < filteredAddedContacts.length; i++) {
                const el = filteredAddedContacts[i];
                if (el.subscription == 'yes' && el.del == 'no' && el.email !== 'Empty' || el.email !== '' && el.mail.includes("@")) {
                    xEmail = xEmail + el.email + ',';
                    sendr2.push(el.email)
                    fnames.push(el.fname)
                }
            }
            
            const sendr = {
                'fnames': fnames,
                'emails': sendr2,
                'len': filteredAddedContacts.length,
                'emailText': emailTxt,
                'broadcastFlyer': broadcastFlyer
            };
            console.log(sendr)
            
            // axios.put(apiUrl() + '/sendmail/24', sendr)
            // .then(response => {
            //     const { result, value, value2 } = response.data
            //     console.log('API call successful:', response.data);
            //     console.log('Result: ', result)
            //     console.log('Value: ', value)
            //     console.log('Value2: ', value2)
            //     successToast('Email broadcast successful')
            // })
            // .catch(error => {
            //     console.error('API call failed:', error.response ? error.response.data : error.message);
            //     console.log('Mail Error: ', error.response.data.message)
            //     return errorToast('Oops..! An error occured')
            // });
            
            var btc = 1
            var loops = 0;
            var useLen = 100;
            const EmailInterval = setInterval(() => {
                // Check if array length is equal to 0
                console.log('Batch: ', btc)
                console.log('---------------------')

                
                if (sendr2.length === 0) {
                    clearInterval(EmailInterval); // Stop the interval
                    // setDialogState('close')
                    successToast('SMS broadcast successful')
                } else {
                    // loops++ || loops === sendr2.length
                    if (sendr2.length < 100) {
                        useLen = sendr2.length
                    }
                    // console.log('sendr2 len: ', sendr2.length)
                    // console.log('count: ', sendr2.slice(0, useLen))
                    // sendr2.splice(0, useLen);
                    
                    // const p = sendr2.slice(0, useLen);
                    sendr['emails'] = sendr2.slice(0, useLen)
                    axios.put(apiUrl() + '/sendmail/24', sendr)
                    .then(response => {
                        const { result, value, value2 } = response.data
                        console.log('API call successful:', response.data);
                        console.log('Result: ', result)
                        console.log('Value: ', value)
                        console.log('Value2: ', value2)
                        successToast('Email broadcast successful')
                        // Remove elements from the beginning of the array
                        sendr2.splice(0, useLen);
                    })
                    .catch(error => {
                        console.error('API call failed:', error.response ? error.response.data : error.message);
                        console.log('Mail Error: ', error.response.data.message)
                        return errorToast('Oops..! An error occured')
                    });
                    
                    if (sendr2.length > 0) {
                        localStorage.setItem('addedContacts', JSON.stringify(sendr2))
                    }
                }
                btc++
            }, 3000);


        } catch (error) {
            console.log('Error broadcasting emails: ', error)
        }
    }

    const forwardSend = async (sendr) => {
        const fwdMail = await axios.put(apiUrl()+'/sendmail/nomail', sendr)
        if (fwdMail) {
            console.log('Result: ', fwdMail.data.result)
            console.log('Value: ', fwdMail.data.value)
            console.log('Value2: ', fwdMail.data.value2)
        } else {
            console.log('Mail Error: ', fwdMail.message)
        }
    }


    const printHelloWorld = (fc) => {
        for (let i = 0; i < 100; i++) {
          console.log('Hello World');
        }
        
        // Remove elements from the beginning of the array
        fc.splice(0, 100);
    };
      


    const smsOnchangeHandler = (e) => {
        // if (e.target.value.length < 161) {
            setSmsText(e.target.value)
            // }else{0
        // }
    }

    const sendSmsBroadcast = () => {
        // console.log(addedContacts.length)
        var newText = smsText;
        var sms_used = +company['sms_used'];
        // var sms_used = +company['sms_used'];
        if (newText == '') {
            return infoToast('Oops..! Type message to proceed!')
        // }else{
        //     return console.log(newText)
        }
        // if (sms_used >= 2000) {
        //     return infoToast('Oops..! You have exceeded your SMS limit!')
        // }
        // newText = newText.replace(/<firstname>/g, 'Joseph')
        // return console.log(sms_used)
        if (addedContacts == '') {
            return infoToast('Select contacts to forward SMS!')
        }
        if(window.confirm("Click 'OK' to forward message to "+addedContacts.length+" contact(s)")){
            // infoToast(smsText)
            // successToast('')
            // for (let i = 0; i < addedContacts.length; i++) {
                var tempHold = '';
                // addedContacts.map(el => { 
                    // 
                    // if (el.phone != 'Empty' || el.phone != '') {
                    //     newText = newText.replace(/<firstname>/g, el.fname)
                    //     newText = newText.replace(/<surname>/g, el.sname)
                    //     newText = newText.replace(/<phone>/g, el.phone)
                    //     fetch("https://apps.mnotify.net/smsapi?key=EDjbRLUSSIfwfGV9gar4kmi8n&to="+el.phone+"&msg="+newText+"&sender_id="+senderId)
                    //     console.log('Text sent to ', el.phone)
                    // }
                    
                    // // tempHold = tempHold + el.phone + ','
                    // // sms_used = sms_used + 1;
                // })
                // return console.log(tempHold)


            
            
            var btc = 1
            const intervalId2 = setInterval(() => {
                // Check if array length is equal to 0
                console.log('Batch: ', btc)
                console.log('---------------------')

                
                var useLen = 100;
                if (addedContacts.length === 0) {
                    clearInterval(intervalId2); // Stop the interval
                    setDialogState('close')
                    successToast('SMS broadcast successful')
                } else {
                    
                    // addedContacts.map(el => { 
                    //     tempHold = tempHold + el.phone + ','
                    // })
                    if (addedContacts.length < 100) {
                        useLen = addedContacts.length
                    }
                    for (let i = 0; i < useLen; i++) {
                        tempHold = tempHold + addedContacts[i].phone + ','
                    }
                    try {
                        // console.log(tempHold)
                        fetch("https://apps.mnotify.net/smsapi?key=EDjbRLUSSIfwfGV9gar4kmi8n&to="+tempHold+"&msg="+newText+"&sender_id="+senderId)
                    } catch (error) {
                        console.log(error.message)
                    }
                    // Remove elements from the beginning of the array
                    addedContacts.splice(0, useLen);
                    tempHold = '';
                    
                }
                btc++
            }, 3000);


            // }
            successToast('SMS Broadcast Successful')
            company['sms_used'] = (sms_used + addedContacts.length)
            updateCompanyDoc(company)
            // setAddedContacts([])
        }
    }


    const addLaraContacts = async () => {
        try {
            var feedback = await axios.put(apiUrl()+'/putcontact/5', addedContacts)
            successToast('Contact Successfully saved')
            feedback = feedback.data.feedback;
            console.log(feedback)
        } catch (error) {
            console.log('Error.. : ', error)
        }
        // if (putContact) {
        //     successToast('Contact Successfully saved')
        // }
        // errorToast('Error')
    }

    const getContacts = async () => {
        const holdContacts = await axios(apiUrl()+'/contacts');
        // console.log(holdContacts.data.result);
        setContacts(holdContacts.data.result)
        
        // const holdContacts = await axios(apiUrl()+'/contacts')
        // .then(
        //     res => console.log('fetch successful'),
        // ).catch(error => console.log('contact fetch error'));
        // console.log(holdContacts.data.result);
    }

    const checkBalance = async () => {
        const config = {
            method: 'get',
            url: 'https://sms.arkesel.com/api/v2/clients/balance-details',
            headers: {
              'api-key': 'eWhqQnZ2c2tOdVlHbmphWXVDalQ='
            }
          };
          
          axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });

        // fetch('https://sms.arkesel.com/sms/api?action=check-balance&api_key=eWhqQnZ2c2tOdVlHbmphWXVDalQ=&response=json')
        // .then(response => console.log(response))
        // .catch(error => console.log(error));

        // const bal = await axios("https://sms.arkesel.com/sms/VWZwdlZDZ2J1Ukx2clZCdXdnV20?action=check-balance");
        // console.log('Acc Bal: ', bal)
    }

    // checkBalance()

    useEffect(() => {
        if (quill) {
          quill.on('text-change', (delta, oldDelta, source) => {
            // // console.log('Text change!');
            // // console.log(quill.getText()); // Get text only
            // // console.log(quill.getContents()); // Get delta contents

            // console.log(quill.root.innerHTML); // Get innerHTML using quill

            // // console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
          });
        }
      }, [quill]);

    // useEffect(() => {
    //     if (quill) {
    //       quill.clipboard.dangerouslyPasteHTML('<p>Email Text goes here...!</p>');
    //     }
    //   }, [quill]);

    useEffect(() => {

        getContacts()
        const smsTextLoc = localStorage.getItem('smsText')
        const textMsgLoc = localStorage.getItem('emailText')
        const addedContactsLoc = localStorage.getItem('addedContacts')
        // console.log(contacts)
        // console.log('-----')
        // console.log(JSON.parse(localStorage.getItem('addedContacts')))
        if (smsText) {
            setSmsText(smsTextLoc)
        }
        if (textMsgLoc) {
            setEditorValue(textMsgLoc)
            // console.log(textMsgLoc)
            if (quill) {
                quill.clipboard.dangerouslyPasteHTML(textMsgLoc);
            }
        }
        if (addedContactsLoc) {
            setShowSearch(true)
            setAddedContacts(JSON.parse(addedContactsLoc))
        }
        
        // const holdContacts = await axios(apiUrl()+'/contacts');
        // console.log(contacts);
        // setContacts(holdContacts.data.result)
    }, [])

    // useEffect(() => {
    //     const textMsgLoc = localStorage.getItem('emailText')
    //     console.log(textMsgLoc)
    //     if (quill) {
    //         quill.clipboard.dangerouslyPasteHTML(textMsgLoc);
    //     }
    // }, [editorValue])

    useEffect(() => {
        setCurSenders(senderIDs)
        // console.log('senderIDs: ', senderIDs)
    },[senderIDs])
    
    useEffect(() => {
        setAllUsers(users)
        // console.log('users: ', users)
    },[users])
    
    useEffect(() => {
        setSmsCount(company?.sms_used)
        // console.log('users: ', users)
    },[company])

    useEffect(() => {
        const collect = [];
        // setEventsRecords(events)
        // console.log('Events:')
        // console.log(events)

        if (events !== null) {
            events.map(evt => 
                evt.del !== 'yesa' ?
                    collect.push(evt)
                :null
            )
        }
        // console.log('Collect: ', collect)
        setEventsRecords(collect)
    }, [events])


  return (
    <>
    <Card className='px-2 py-5 m-3'>
        
        <p className='page-header'><span className="float-left"><TbSend size={20} /></span>&nbsp; Broadcast Message</p>
        
        <Dialog01 declareState={dialogState} body='LOAD' icon={<PiWarningOctagonBold />} head='Do not close dialog' />

        <div>
            
            {/* <FormInput name='fname' type='text' size='md' label='Firstname' className='m-2' inIcon={<TbUserEdit />} required/>
            <FormInput name='sname' type='text' size='md' label='Last Name' className='m-2' inIcon={<TbUserEdit />} />
            <FormInput name='phone' type='number' size='md' label='Phone' min='0' className='m-2' inIcon={<TbPhone />} /> */}

            <div className="m-2">
                <Select label="Send To">
                    <Option value='all' onClick={addAllContacts}>All Contacts</Option>
                    <Option value='selected' onClick={() => setShowSearch(true)}>Selected Contacts</Option>
                </Select>
            </div>
            <div className="m-2">
                <Select label="Add Flyer(Optional)">
                    <Option value='Null'>None</Option>

                    {eventsRecords ? (
                        eventsRecords.map((evt, x) => 
                            <Option key={x} onClick={() => setBroadcastFlyer(evt.flyer)}>
                                <img src={evt.flyer} className='w-4 float-left' alt="" /> &nbsp;&nbsp; 
                                {evt.title} 
                            </Option>
                        )
                    ) :null}
                </Select>
            </div>
            <div className="m-2">
                {/* <p className="blue-text">Choose Sender Id</p> className='custom-select' */}
                <Select label="Sender ID (From)">
                    <Option value='Soho Friday' onClick={() => setSenderId('Soho Friday')}>Soho Friday &nbsp; <span className='float-right text-xs text-green-500 italic'>Primary</span></Option>
                        
                    {/* { curSenders != null ? 
                        curSenders.map(el => {
                        return (
                            <Option value={el.sid} onClick={() => {setSenderId(el.sid)}}>{el.sid} &nbsp;- <span className='float-right text-xs text-sky-500 italic'>Secondary</span></Option>
                        )
                    })
                    :null} */}
                    
                    {/* { curSenders ? 
                        curSenders.map(el => {
                            return(
                                <Option value='Soho Friday' onClick={setNewSenderId(el.sid)}>{el.sid} &nbsp;<span className='float-right text-xs text-sky-500 italic'>Secondary</span></Option>
                            )
                        })
                    :null} */}
                </Select>
            </div>
            { showSearch === true ? 
            <>
                { contacts != null ?
                    <FormInput name='search_contact' onChange={searchContact} value={newSearchKey} type='text' size='md' label='Search Contact' className='m-2' inIcon={<TbSearch />} />
                :null}
                <div className='selected-contacts-container'>
                    <p className='text-gray-200 text-xs capitalize mx-3 mt-4 mb-2'>{addedContacts ? addedContacts.length :null} Contacts Selected
                        <Tooltip className='tooltip-des' content='Save'><span onClick={saveContentToLoc} className='search-item-save'><MdOutlineSave size={20} /></span></Tooltip>
                        <Tooltip className='tooltip-des' content='Refresh'><span onClick={refreshContacts} className='search-item-save'><MdOutlineRefresh size={20} /></span></Tooltip>
                    </p>
                    
                    <div className='selected-contacts'>
                        { searchResult != null ?
                            searchResult.map(el => 
                                <p key={Math.random()} className='added-items'><BiSolidCircle size='8' className='float-left' />&nbsp; {el.fname != 'Empty' ? el.fname :null}&nbsp;{el.sname != 'Empty' ? el.sname :null} <br></br> {el?.phone+' / '}{el.email ? el.email.substring(0,12)+'...' :null} &nbsp;
                                    <span onClick={() => addContact(el)} className='search-item-add'>Add</span>
                                </p>
                            )
                        :null}

                        { addedContacts != null ?
                            addedContacts.map(el => 
                                // <p key={Math.random()} className='search-items'><BiSolidCircle size='8' className='float-left' />&nbsp; {el.fname != 'Empty' ? el.fname :null}&nbsp;{el.sname != 'Empty' ? el.sname :null} <br></br> {el.phone != 'Empty' ? el.phone+' / '+el.email.substring(0,12)+'...' :el.email} &nbsp; 
                                <p key={Math.random()} className='search-items'><BiSolidCircle size='8' className='float-left' />&nbsp; {el?.fname}&nbsp;{el?.sname} <br></br> {el?.phone+' / '}{el.email ? el.email.substring(0,12)+'...' :null} &nbsp; 
                                    <span onClick={() => removeContact(el)} className='search-item-x'><FaTimes size={12} /></span>
                                </p>
                            )
                        :null}
                    </div>
                </div>
            </>
            :null}
            {/* <FormTextarea name='emails' type='text' maxLength='50' size='lg' label='Type Emails (Separate with comma",")' className='m-2'/> */}
        
            <FormTextarea name='sms_text' type='text' size='md' onChange={smsOnchangeHandler} value={smsText?smsText:''} label='Type SMS Here' className='mx-2 mt-4 mb-2'/>
            <Button className='mx-2 z-10' color='light-blue' variant='gradient' onClick={sendSmsBroadcast}>Send SMS Broadcast &nbsp; <TbSend size={16} className='float-right' /></Button>
            <div className="blue-text mb-7">Total SMS Used: {smsCount}</div>
            {/* <Button className='mx-2 mb-7' color='pink' variant='gradient' onClick={addLaraContacts}>save contact &nbsp; <TbSend size={16} className='float-right' /></Button> */}
        </div>

        
        {/* <TxtEditor /> */}

      {/* <Editor
        apiKey='3va0hdu8v5w1xzzlcpfyy4c5qc29q7ask4fea5fy0gfzhfno'
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={editorValue}
        init={{
            height: 300,
            plugins: 'ai tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss',
            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
            tinycomments_mode: 'embedded',
            tinycomments_author: 'Author name',
            mergetags_list: [
            { value: 'First.Name', title: 'First Name' },
            { value: 'Email', title: 'Email' },
            ],
            ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
        }}
      /> */}

        <div className='w-[calc(100%-16px)] h-40 mx-2 mt-4 mb-20'>
            <div ref={quillRef} />
        </div>

        <p className='text-gray-500 text-xs capitalize mx-3 mt-6 mb-2'>Send to { addedContacts.reduce((total, item) => item.email != 'Empty' ? total + 1 :total, 0) } contacts with valid emails</p>

        <form onSubmit={sendEmailBroadcast}>
            {/* <FormTextarea name='email_text' type='text' size='md' label='Type Email Here' className='mx-2 mt-4 mb-2'/> */}
            <Button type='submit' className='mx-2 mt-6 mb-2' variant='gradient' color='green'>Send Email Broadcast &nbsp; <TbSend size={16} className='float-right' /></Button>
        </form>
        
    </Card>
    </>
  )
}


export const SendSMS = (phone, text) => {
    fetch('https://apps.mnotify.net/smsapi?key=EDjbRLUSSIfwfGV9gar4kmi8n&to='+phone+'&msg='+text+'&sender_id=Promoplux')
    .then(
        res => console.log('SMS Sent')
        // res => successToast('SMS Broadcast Successful'),
    ).catch(err => console.log('Failed'))
}

// export const apiUrl2 = () => {
//     const url = apiUrl()+'/api';
//     return url;
// }

export default MsgBroadcast