
import { Card, Input,
  Popover,
  PopoverHandler,
  PopoverContent,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { apiUrl, errorToast, infoToast, successToast } from '../../utils/firebase/firebase.utils'
import { CiEdit } from "react-icons/ci";
import { MdOutlineEdit, MdOutlineFileUpload, MdOutlinePersonSearch } from "react-icons/md";
import { RiDeleteBin2Line } from "react-icons/ri";
import { HiOutlineUsers } from 'react-icons/hi2';
import { GoPlus } from 'react-icons/go';
import { AiOutlineCloudUpload, AiOutlinePlus } from 'react-icons/ai';
import { BiRefresh } from 'react-icons/bi';
import { TbMessage, TbPhone, TbRefresh, TbUserEdit } from 'react-icons/tb';
import FormTextarea from "../../components/form/textarea.component";
import FormInput from "../../components/form/forminput.component";
import {ExcelRenderer, OutTable} from 'react-excel-renderer';

const ContactsPage = () => {


  const fetchContacts = async () => {
    const getContacts = await axios(apiUrl()+'/contacts');
    // const getContacts = await axios('https://mensahdb.pivoapps.net/contacts');
    // console.log('Contacts: ', getContacts.data.result)
    setContacts(getContacts.data.result)
    setApiContacts(getContacts.data.result)
  }

  const [ apiContacts, setApiContacts ] = useState();
  const [ contacts, setContacts ] = useState();
  const [ searchText, setSearchText ] = useState('');
  const [ dialogHead, setDialogHead ] = useState('Add Contact');
  const [ excelRecords, setExcelRecords ] = useState();
  
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  
  
  var defaultValues = {
    fname: '', 
    sname: '', 
    phone: '', 
    email: '', 
    subscription: 'yes',
    del: 'no'
  }
  const [formFields, setFormFields] = useState(defaultValues)

  const handleOnchange = (event) => {
    const { name, value } = event.target;
    setFormFields({...formFields, [name]: value})
    // console.log(value) 
  }

  const searchContact = (e) => {
    const txt = e.target.value;
    // console.log(txt)
    setSearchText(txt)

    if (contacts) {
      if (txt != '') {
        const found = apiContacts.filter(el => el.fname?.toLowerCase().includes(txt.toLowerCase()) || el.sname?.toLowerCase().includes(txt.toLowerCase()) || el.phone?.toLowerCase().includes(txt.toLowerCase()))
        // console.log(found)
        setContacts(found)
      } else {
        setContacts(apiContacts)
      }
    }
  }

  const addContact = () => {
    setDialogHead('Add Contact')
    setFormFields(defaultValues)
    handleOpen()
  }

  const updateContact = async (receiver) => {
    // event.preventDefault();
    setFormFields(receiver)
    setDialogHead("Edit "+receiver.fname+"'s Contact")
    handleOpen()
  }

  const postContact = async (event) => {
    event.preventDefault();

    // return console.log(formFields)

    // const formValues = {
    //   fname: event.target.fname.value,
    //   sname: event.target.sname.value,
    //   phone: event.target.phone.value,
    //   email: event.target.email.value
    // }
    // return console.log('Im inside', formValues)
    try {
      var feedback = await axios.post(apiUrl()+'/addcontact', formFields)
      feedback = feedback?.data.result;
      console.log(feedback)
      successToast('Contact successfully added')
      fetchContacts()
      handleOpen()
    } catch (error) {
      errorToast('Unable to add contact')
      console.log('Error.. : ', error)
    }
  }

  const putContact = async () => {
    // event.preventDefault();

    formFields['subscription'] = 'yes';
    formFields['del'] = 'no';

    // return console.log(formFields)
    // const formValues = {
    //   fname: formFields.fname,
    //   sname: formFields.sname,
    //   phone: formFields.phone,
    //   email: formFields.email,
    //   subscription: 'yes',
    //   del: 'no'
    // }

    
    try {
      var feedback = await axios.put(apiUrl()+'/putcontact/'+formFields.id, formFields)
      successToast(formFields.fname+"'s contact updated")
      // const newContacts = contacts.filter(el => el.id != formFields.id)
      // setContacts(newContacts)
      fetchContacts()
    } catch (error) {
        errorToast('Network Error..! Try update after some time')
        console.log('Update Error.. : ', error)
    }
    handleOpen()
  }

  const deleteContact = async (contact) => {

    if (!window.confirm("Click to proceed deletion of "+contact.fname+"'s contact")) {
      return
    }
    
    const formValues = {
      fname: contact.fname,
      sname: contact.sname,
      phone: contact.phone+' - deleted',
      email: contact.email+' - deleted',
      subscription: 'yes',
      del: 'yes'
    }

    
    try {
      // console.log(contact.id)
      // console.log(contact)
      var feedback = await axios.put(apiUrl()+'/putcontact/'+contact.id, formValues)
      successToast(contact.fname+"'s contact deleted")
      const newContacts = contacts.filter(el => el.id != contact.id)
      setContacts(newContacts)
      // feedback = feedback.data.feedback;
      // console.log(feedback)
    } catch (error) {
        errorToast('Network Error..! Try deleting after some time')
        console.log('Deletion Error.. : ', error)
    }
    
  }

  const fileHandler = async (event) => {
    event.preventDefault()
    
    const recs = [];
    let fileObj = event.target.exfile.files[0];

    if (fileObj) {}else{
      return infoToast('Select Excell file to proceed')
    }

    // console.log('Inside')
    // return console.log(fileObj.type)
    
    ExcelRenderer(fileObj, (err, resp) => {
      if(err){
        console.log(err);            
      }else{
        // ((total, item) => total + item.total, 0)
        var acc = 0;
        var A, B, C, D;
        const total = resp.rows.map(item => item[0] != null && item[2] != null ? acc = acc + 1 :null)
        for (let i = 1; i < acc; i++) {

          A = resp.rows[i][0];
          B = resp.rows[i][1];
          C = resp.rows[i][2];
          D = resp.rows[i][3];

          if (A == null) {
            A = 'Empty'
          }
          if (B == null) {
            B = 'Empty'
          }
          if (C == null || C == '' || C == ' ') {
            C = 'Empty'
          }else{
            // console.log('C = ', '-'+C+'-')
            // // if (C.includes('+') == true) {
            //   // C = C.replace('+', '')
            //   if (C.substring(0,1) == '+') {
            //     C = C.replace('+', '')
            //   }
            //   if (C.substring(0,3) == '233') {
            //     C = C.replace('233', '0')
            //   }
            //   if (C.substring(0,4) == '+233') {
            //     C = C.replace('+233', '0')
            //   }
            // // }
          }
          if (D == null) {
            D = 'Empty'
          }

          recs.push({
            fname: A,
            sname: B,
            phone: C,
            email: D,
          })
        }

        for (let i = 0; i <= recs.length; i++) {
          // console.log(recs[i]);
          sendImportedContacts(recs[i])
        }
        // sendImportedContacts(recs[0])
        successToast('Contact Import Started.. ')
        // refreshPage()

        // console.log('fname', resp.rows[2][3]);
        // console.log(acc);
        // console.log('recs[1]', recs.length);
      }
    });               
    
  }

  const sendImportedContacts = async (sendImport) => {

    // return console.log(sendImport)
    
    try {
      var feedback = await axios.post(apiUrl()+'/addcontact', sendImport)
      feedback = feedback?.data.result;
      // console.log(feedback)
      console.log('Imported '+sendImport?.fname)
      // successToast('Contact Import Successful')

    } catch (error) {
      // errorToast('Unable to Import contact')
      feedback = feedback?.data.result;
      console.log(feedback)
      console.log('Import Error.. ')
      // console.log('Import Error.. ', feedback.data.message)
    }

  }


  useEffect(() => {
    fetchContacts()
  }, [])


  useEffect(() => {
    // console.log(contacts)
  }, [contacts])


  
  return (
    <Card className='mx-2 mt-2 mb-12 px-4 py-5'>
      <div className="contacts-head">
        <h1><HiOutlineUsers className='float-left m-0.5'/>&nbsp;Contacts</h1>

        <p className='w-16 border-r border-r-gray-400' onClick={addContact} variant="gradient"><AiOutlinePlus className='float-left m-0.5' size={13} />&nbsp;Add</p>
        <Dialog size="sm" open={open} handler={handleOpen}>
          <DialogHeader>{dialogHead}</DialogHeader>
          <DialogBody>
            <form className="gen-form" onSubmit={postContact}>
              <FormInput name='fname' onChange={handleOnchange} value={formFields.fname ? formFields.fname :''} type='text' size='md' label='Firstname' className='m-2' inIcon={<TbUserEdit />} required/>
              <FormInput name='sname' onChange={handleOnchange} value={formFields.sname ? formFields.sname :''} type='text' size='md' label='Last Name' className='m-2' inIcon={<TbUserEdit />} />
              <FormInput name='phone' onChange={handleOnchange} value={formFields.phone ? formFields.phone :''} type='number' size='md' label='Phone' min='0' maxLength='10' className='m-2' inIcon={<TbPhone />} required/>
              <FormInput name='email' onChange={handleOnchange} value={formFields.email ? formFields.email :''} type='email' size='md' label='Email' className='m-2' inIcon={<TbMessage />} />
            </form>
          </DialogBody>
          <DialogFooter>
            {/* <Button variant="outlined" color="red" onClick={(e) => {e.reset()}} className="mr-1"><span>Close</span></Button> */}
            <Button variant="gradient" color="red" onClick={handleOpen} className="mr-1"><span>Close</span></Button>
            { dialogHead == 'Add Contact' ?
              <Button type='submit' onClick={postContact} variant="gradient" color="green"><span>Save Contact</span></Button>
            :
              <Button type='button' onClick={putContact} variant="gradient" color="green"><span>Update Contact</span></Button>
            }
            
            {/* <Button variant="gradient" color="green" onClick={handleOpen}>
              <span>Confirm</span>
            </Button> */}
          </DialogFooter>
        </Dialog>

        <Popover placement="bottom">
          <PopoverHandler>
            <p className='w-20 border-r border-r-gray-400'><MdOutlineFileUpload className='float-left m-0.5' size={14} />&nbsp;Import</p>
          </PopoverHandler>
          <PopoverContent>
            <form onSubmit={fileHandler}>
              <input className="mb-2 mr-2" type="file" name="exfile" />
              <Button className="rounded-md float-right" type='submit' size="sm" variant="gradient"><span>Import</span></Button>
            </form>
          </PopoverContent>
        </Popover>
        <p className='w-22' onClick={refreshPage}><TbRefresh className='float-left m-0.5' size={14} />&nbsp;Refresh</p>

        <div className='w-full pt-2 mb-4 flex'>
          <Input icon={<MdOutlinePersonSearch />} onChange={searchContact} value={searchText} type='text' size='lg' label='Search Contact'className='bg-gray-50 text-gray-700 w-full' />
        </div>
      </div>

      { contacts != null ? 
        contacts.map(el => 
          <div key={el.id} className="contact">
            <h3>{el.fname ? el.fname :null} { el.sname ? el.sname :null}</h3>
            <h4>{el.phone ? el.phone :null} / <span>{el.email ? el.email :null}</span></h4>
            
            <p className='w-20' onClick={() => {deleteContact(el)}}><RiDeleteBin2Line className='float-left m-0.5' size={14} />&nbsp;Delete</p>
            <p className='w-16 border-r border-r-gray-400' onClick={() => {updateContact(el)}}><MdOutlineEdit className='float-left m-0.5' size={13} />&nbsp;Edit</p>
          </div>
        )
      :<p className='text-xs m-2 italic text-gray-500'>No Contacts Found</p>}

    </Card>
  )
}

export default ContactsPage

export const refreshPage = () => {
  window.location.reload()
}