import React, { useState } from 'react'
import './navbar.styles.scss'
import { Button, IconButton, Menu, MenuHandler, MenuItem, MenuList, Typography } from '@material-tailwind/react'
import { BsGear, BsGearFill, BsPerson, BsSearch, BsThreeDots } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import { TbRefresh, TbSettings2, TbUserCog, TbUsersPlus } from 'react-icons/tb'
import { LuContact2, LuLogOut } from 'react-icons/lu'
import { MdEventAvailable, MdOutlineContentPasteSearch, MdOutlineMarkEmailRead, MdOutlineRefresh } from 'react-icons/md'
import useAuth from '../../hooks/useAuth'
import { signOutUser, successToast } from '../../utils/firebase/firebase.utils'
import { HiMenuAlt3 } from 'react-icons/hi'
import { refreshPage } from '../../routes/admin/contact.components'


const MyNavbar = () => {
  
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenu2, setOpenMenu2] = useState(false);
  const { currentUser, setCurrentUser } = useAuth();
  const navigate = useNavigate();

  // const pageRefresh = () => {
  //     window.location.reload();
  // }

  const signOutHandler = async () => {
    await signOutUser();
    setCurrentUser(null);
    successToast('Sign Out Successful..!');

    localStorage.setItem('curUser', '');
    navigate('/');
    // console.log(localStorage.getItem('curUser'));
  }


  return (
    <>
    <div className='navbar-container'>
        <div className='navbar-content'>
          <div className='nav-left'>
            <Link to="/"><img src="https://i.pinimg.com/474x/16/df/e4/16dfe40ae4f39e0e76674f3fbb3bf626.jpg" alt="" /></Link>
            {/* <h1>PivoApps</h1> */}
          </div>
          {/* <div className='nav-left'><h1>PivoApps</h1></div> */}
          <div className='nav-right'>

            { currentUser ? 
            <>
              <Menu open={openMenu} handler={setOpenMenu} allowHover
                animate={{
                  mount: { y: 0 },
                  unmount: { y: 25 },
                }}
                placement='bottom-end'
              >
                <MenuHandler>
                  <IconButton size='sm' className='icon-btn-r'><HiMenuAlt3 size='20'/></IconButton>
                </MenuHandler>

                <MenuList>
                  <Typography variant="small" className="font-medium px-2">
                    Welcome {currentUser.displayName}!
                  </Typography>

                  <hr className="my-2 border-blue-gray-100" />

                  {/* <Link to='/profile'>
                    <MenuItem className="flex items-center gap-2">
                      <TbUserCog size='15'/>
            
                      <Typography variant="small" className="font-medium">
                        Profile
                      </Typography>
                    </MenuItem>
                  </Link> */}

                  <Link to='/events'>
                    <MenuItem className="flex items-center gap-2">
                      <MdEventAvailable size='15'/>
                      <Typography variant="small" className="font-medium">
                        Manage Event
                      </Typography>
                    </MenuItem>
                  </Link>
                  
                  {/* <Link to='/influencers'>
                    <MenuItem className="flex items-center gap-2">
                      <TbUsersPlus size={15} />
                      <Typography variant="small" className="font-medium">
                        Manage Influencer
                      </Typography>
                    </MenuItem>
                  </Link> */}
                  
                  <Link to='/broadcast'>
                    <MenuItem className="flex items-center gap-2">
                      <MdOutlineMarkEmailRead size={15} />
                      <Typography variant="small" className="font-medium">
                        Broadcast Email
                      </Typography>
                    </MenuItem>
                  </Link>

                  <Link to='/contacts'>
                    <MenuItem className="flex items-center gap-2">
                      <LuContact2 size={15} />
                      <Typography variant="small" className="font-medium">
                        Manage Contacts
                      </Typography>
                    </MenuItem>
                  </Link>
                  
                  {/* <Link to='/checkout'>
                    <MenuItem className="flex items-center gap-2">
                      <MdOutlineContentPasteSearch size={15} />
                      <Typography variant="small" className="font-medium">
                        Checkout
                      </Typography>
                    </MenuItem>
                  </Link>
                  
                  <MenuItem className="flex items-center gap-2">
                    <TbSettings2 size={15} />
                    <Typography variant="small" className="font-medium">
                      Settings
                    </Typography>
                  </MenuItem> */}

                  <Link to='/settings'>
                    <MenuItem className="flex items-center gap-2">
                      <BsGear size='15'/>
            
                      <Typography variant="small" className="font-medium">
                        Settings
                      </Typography>
                    </MenuItem>
                  </Link>
                  
                  <hr className="my-2 border-blue-gray-100" />

                  <MenuItem className="flex items-center gap-2" onClick={signOutHandler}>
                    <LuLogOut size={15} />

                    <Typography variant="small" className="font-medium" onClick={signOutHandler}>
                      Sign Out
                    </Typography>
                  </MenuItem>
                </MenuList>
                
              </Menu>

              <IconButton onClick={refreshPage} size='sm' className='icon-btn-r'><TbRefresh size='20'/></IconButton>

              {/* <Menu open={openMenu2} handler={setOpenMenu2} allowHover
                animate={{
                  mount: { y: 0 },
                  unmount: { y: 25 },
                }}
                placement='bottom-end'
              >
                <MenuHandler>
                  <IconButton size='sm' className='icon-btn-r'><BsGear size='16'/></IconButton>
                </MenuHandler>

                <MenuList>
                  
                </MenuList>
                
              </Menu> */}
              
            </>
            :
              <Link to='/login'><Button className='float-right mt-1 bg-pink-600 hover:bg-pink-700' size='sm'>Sign In</Button></Link>
            }

            {/* <IconButton className='icon-btn-r'><BsPerson size='16'/></IconButton>
            <IconButton className='icon-btn-r'><BsGear size='16'/></IconButton> */}
            {/* <IconButton size='sm' className='icon-btn-r'><BsSearch size='16'/></IconButton> */}

          </div>
        </div>
    </div>
    </>
  )
}

export default MyNavbar