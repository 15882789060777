
import React, { useContext, useEffect, useState } from 'react'
import FormInput from '../../components/form/forminput.component'
import FormTextarea from '../../components/form/textarea.component'
import { TbClock, TbCloud, TbCurrency, TbCurrentLocation, TbDashboard, TbHash, TbLoadBalancer, TbLocation, TbMap, TbPhone, TbUser, TbUserEdit } from 'react-icons/tb'
import { Button, Option, Select } from '@material-tailwind/react'
import FormSelect from '../../components/form/formSelect.component'
import { MdOutlineFileUpload } from 'react-icons/md'
import { PiInstagramLogo, PiTiktokLogoThin } from 'react-icons/pi'
import { FaSquareTwitter } from 'react-icons/fa6'
import { FaSave, FaSnapchatSquare } from 'react-icons/fa'
import { SiInstagram } from 'react-icons/si'
import { LuSubtitles } from 'react-icons/lu'
import { BsCheck, BsCheck2, BsSave, BsSnapchat } from 'react-icons/bs'
import { SlSocialTwitter } from "react-icons/sl";
import { EventsContext } from '../../context/events.context'
import { errorToast, imageDb, infoToast } from '../../utils/firebase/firebase.utils'
import { processSecs } from './new_event.components'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { v4 } from 'uuid';
import useAuth from '../../hooks/useAuth'

const NewEventForm = ({ send }) => {
  // console.log('Start')
  
  const { currentUser } = useAuth(); 
  const { createEvent, updateEvent } = useContext(EventsContext)

  var defaultValues = {
    title: '', 
    host: '', 
    ev_date: '', 
    venue: '', 
    mapurl: '', 
    ig: '', 
    snap: '', 
    tx: '', 
    tk: '',
    del: 'no',
    flyer: '',
    ticket: 'no',
    ticket_qty: '1000',
    ticket_sold: '0',
    host_msg: '',
    cur: '',
    rate: '',
    capacity: '',
    city: '',
    category: '',
    start_at: '',
    end_at: '',
    status: 'active',
    secs: '',
    user: currentUser?.uid,
    attendance: {},
    created_at: new Date,
    updated_at: '',
  }
  const [formFields, setFormFields] = useState(defaultValues)
  const [rate, setRate] = useState(false)
  const [img, setImg] = useState('')
  const [imgUrl, setImgUrl] = useState([])

  
  const handleFlyerUpload = (event) =>{
    event.preventDefault()
    if(img !== ''){
      const imgRef = ref(imageDb,`files/${v4()}`)
      uploadBytes(imgRef,img).then(val=>{
        // console.log(val)
        getDownloadURL(val.ref).then(url=>{
          setImgUrl(data=>[...data,url])
          formFields['flyer'] = imgUrl
          handleCreateEvent(event, url)
        })
      })
    }else{
      return infoToast('Oops..! Select event flyer to proceed')
    }
  }
  
  const handleFlyerUpload2 = (event) =>{
    event.preventDefault()
    if(img !== ''){
      const imgRef = ref(imageDb,`files/${v4()}`)
      uploadBytes(imgRef,img).then(val=>{
        // console.log(val)
        getDownloadURL(val.ref).then(url=>{
          setImgUrl(data=>[...data,url])
          formFields['flyer'] = imgUrl
          handleEventUpdate(event, url)
        })
      })
    }else{
      if (send['flyer'] == '' && send['status'] == 'active') {
        return infoToast('Oops..! Event flyer cannot be empty')
      }
      handleEventUpdate(event, send['flyer'])
    }
    return
  }

  const handleOnchange = (event) => {
    const { name, value } = event.target;
    setFormFields({...formFields, [name]: value})
    if (name == 'rate') {
      // setRate(!rate)
      // console.log(name)
      if (value == 'free') {
        setRate(false)
      } else {
        setRate(true)
      }
      // console.log(rate)
    }
    // console.log(value) 
  }

  const handleCreateEvent = async (event, getUrl) => {
    event.preventDefault();
    if (!formFields['title'] || !formFields['host'] || !formFields['ev_date'] || !formFields['venue'] || !img || !formFields['city'] || !formFields['start_at']) {
      return errorToast('Oops..! Fill in required fields')
    }

    // handleFlyerUpload()
    formFields['flyer'] = getUrl;
    const secs = processSecs(formFields['ev_date'])
    formFields['secs'] = secs;
    await createEvent(formFields)
    setFormFields(defaultValues)
    // console.log(secs);
  }

  const handleEventUpdate = (event, getUrl) => {
    event.preventDefault();
    if (!formFields['title'] || !formFields['host'] || !formFields['ev_date'] || !formFields['venue'] || !formFields['city'] || !formFields['start_at']) {
      return errorToast('Oops..! Fill in required fields')
    }

    if (send) { 
      setImg(send['flyer']) 
      console.log('imgUrl sel')
      console.log(img)
      console.log(imgUrl)
    }
    formFields['flyer'] = getUrl
    // return console.log(formFields)
    const toastMsg = 'Event update successful';
    updateEvent(formFields, toastMsg)
  }

  // https://firebasestorage.googleapis.com/v0/b/promoplux-107b9.appspot.com/o/files%2Ffdab3ad2-a452-4300-b74a-0525afceccf2?alt=media&token=e5acc537-7967-4ba7-846f-beb54fc85bac
  // https://firebasestorage.googleapis.com/v0/b/promoplux-107b9.appspot.com/o/files%2F6153d0bc-6440-4f56-85d3-e3a1d5e40a87?alt=media&token=390c95fa-e0c0-42ae-a8df-a689b96e3fdd

  useEffect(() => {
    if (send) {
      setFormFields(send);
      if (send.rate != 'free') {
        setRate(true)
      }
    }
    // console.log(currentUser?.uid)
  }, []);

  // useEffect(() => {
  //   console.log(imgUrl)
  // }, [img]);

  return (
    <>
    <form className="gen-form">

      { send ?
        <div className="m-2">
            <select className='custom-select' name='status' onChange={handleOnchange}>
              { send.status === 'active' ?
              <>
                <option value='active'>Active</option>
                <option value="inactive">Inactive</option>
              </>
              : 
              <>
                <option value='inactive'>Inactive</option>
                <option value="active">Active</option>
              </>
              }
            </select>
        </div>
      :null}

{/* rate: '',
    city: '',
    category: '',
    start_at: '', */}
        <div className="m-2">
            <select className='custom-select' name='category' onChange={handleOnchange}>
              { send ? send.category != null ? <option>{send.category}</option> :null:null}
              <option value="no">-- Event Category --</option>
              <option>Entertainment</option>
              <option>Educational</option>
              <option>Professional</option>
            </select>
        </div>
        <FormInput name='title' type='text' size='md' label='Event Title' onChange={handleOnchange} value={formFields.title} className='m-2' inIcon={<LuSubtitles />} required/>
        <FormInput name='host' type='text' size='md' label='Host' onChange={handleOnchange} value={formFields.host} className='m-2' inIcon={<TbUser />} required/>
        <FormInput name='ev_date' type='date' size='md' label='Date' onChange={handleOnchange} value={formFields.ev_date} className='m-2' required/>
        <FormInput name='venue' type='text' size='md' label='Venue' onChange={handleOnchange} value={formFields.venue} className='m-2' inIcon={<TbCurrentLocation />} required/>
        <FormInput name='mapurl' type='text' size='md' label='Map Url' onChange={handleOnchange} value={formFields.mapurl} className='m-2' inIcon={<TbMap />} required/>
        <FormInput name='city' type='text' size='md' label='City' onChange={handleOnchange} value={formFields.city} className='m-2' inIcon={<TbLocation />} required/>
        
        {/* <div className="m-2">
          <p className='blue-text'>-- Ticket Rate/Currency --</p>
          <select className='custom-select' name='cur' onChange={handleOnchange}>
            { send ? send.cur != '' ? 
              <option value={send.cur}>{send.cur}</option>
            :null:null}
            <option value='GH₵'>GH₵</option>
            <option value='£'>GBP</option>
            <option value='€'>EUR</option>
            <option value='$'>USD</option>
          </select>
        </div> */}

        {/* <FormInput name='rate' type='number' min='0' size='md' label='Rate' maxLength='5' onChange={handleOnchange} value={formFields.rate} className='m-2' inIcon={<TbLoadBalancer />}/> */}
        <FormInput name='start_at' type='text' size='md' label='Start At (ex. 10:00PM)' onChange={handleOnchange} value={formFields.start_at} className='m-2' inIcon={<TbClock />} required/>

        <div className="social-links-container">
        
          <p className='blue-text'>-- Rate --</p>
          <div className='px-2'>
            <select className='custom-select' name='rate' onChange={handleOnchange}>
              { send ? send.rate === 'free' ? 
              <>
                <option value='free' selected>Free</option>
                <option value='ticketed'>Ticketed</option>
              </>
              :
              <>
                <option value='free'>Free</option>
                <option value='ticketed' selected>Ticketed</option>
              </>
              :
              <>
                <option value='free' selected>Free</option>
                <option value='ticketed'>Ticketed</option>
              </>
              }
            </select>
          </div>

          <FormInput name='capacity' type='number' size='md' min='0' label='Capacity' onChange={handleOnchange} value={formFields.capacity} className='m-2' inIcon={<TbDashboard />}/>
            
          { rate === true ?
          <>
            <p className='blue-text'>-- Ticket Rate/Currency --</p>
            <FormInput name='rate' type='number' min='0' size='md' label='Rate' maxLength='5' onChange={handleOnchange} value={formFields.rate} className='m-2' inIcon={<TbLoadBalancer />}/>
            <div className='px-2'>
              <select className='custom-select' name='cur' onChange={handleOnchange}>
                { send ? send.cur != '' ? 
                  <option value={send.cur}>{send.cur}</option>
                :null:null}
                <option value='GH₵'>GH₵</option>
                <option value='£'>GBP</option>
                <option value='€'>EUR</option>
                <option value='$'>USD</option>
              </select>
            </div>

            <p className='blue-text'>-- Purchase Ticket Via --</p>
            <FormInput name='ussd' type='text' size='md' label='USSD Code' onChange={handleOnchange} value={formFields.ussd} className='m-2' inIcon={<TbHash />}/>
            <FormInput name='ticket' type='text' size='md' label='URL' onChange={handleOnchange} value={formFields.ticket} className='m-2' inIcon={<TbCloud />}/>
            {/* <select className='custom-select' name='ticket' onChange={handleOnchange}>
              { send ? send.cur != null ? 
              <>
                <option value="Free">-- Ticket Type --</option>
                <option value={send.ticket} selected>{send.ticket}</option>
              </>
                :<option value="Free">-- Ticket Type --</option>
              :null}
              <option value="USSD">USSD</option>
              <option value="Link">Link</option>
              <option value="Free">Free</option>
            </select> */}
          </>
          :null}
          <FormInput onChange={handleOnchange} value={formFields?.ticket_qty} name='ticket_qty' type='number' min='0' size='md' label='Tickets Available for Sale' className='m-2'/>
        </div>

        <div className='social-links-container'>
          <p className='blue-text'>Add social links here</p>
          <FormInput name='ig' type='text' size='md' label='Instagram' className='m-2' onChange={handleOnchange} value={formFields.ig} inIcon={<PiInstagramLogo />}/>
          <FormInput name='snap' type='text' size='md' label='Snapchat' className='m-2' onChange={handleOnchange} value={formFields.snap} inIcon={<BsSnapchat />}/>
          <FormInput name='tx' type='text' size='md' label='Twitter' className='m-2' onChange={handleOnchange} value={formFields.tx} inIcon={<SlSocialTwitter />}/>
          <FormInput name='tk' type='text' size='md' label='Tiktok' className='m-2' onChange={handleOnchange} value={formFields.tk} inIcon={<PiTiktokLogoThin />}/>
        </div>

        <FormTextarea onChange={handleOnchange} value={formFields.host_msg} name='host_msg' type='text' maxLength='750' size='md' label='Message from host' className='m-2'/>
        
        <FormInput onChange={(e)=>setImg(e.target.files[0])} name='flyer' type='file' size='md' label='Upload Flyer' className='m-2' inIcon={<MdOutlineFileUpload />} required/>

        { send ? 
          <div className='w-16 mx-4 my-1 float-left'>
            <img className='rounded-md' src={send.flyer}/>
          </div>
        :null}

        <Button type='submit' onClick={send ? handleFlyerUpload2 : handleFlyerUpload} className='float-right mx-2 my-4' variant='gradient' color='green'>
          {send ?
          <>Update Event &nbsp; <FaSave size={14} className='float-right' /></>
          :<>Save Event &nbsp; <BsSave size={14} className='float-right' /></>
          }
        </Button>
        <p className='clear-both'>&nbsp;</p>

    </form>
    </>
  )
}

export default NewEventForm