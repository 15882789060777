
import React from 'react'
import QRCode from 'react-qr-code'
import { newDate } from '../routes/admin/new_event.components'
import '../routes/other-styles.styles.css'
import FormInput from '../components/form/forminput.component'
import { GoShieldCheck } from 'react-icons/go'
import { Button, Input } from '@material-tailwind/react'
import { useState } from 'react'

const GetQRCode = ({ code, event, qr }) => {

  return (
    <div className='p-5 m-2 border-2 rounded-md border-gray-400'>
        {/* <QRCode value={787805654} size={200} style={{ width: '100%' }}/> */}
        {/* <div className='qr-right'>
            <div>
                <p className='text-xs text-gray-500/80 uppercase text-right'>Tickets</p>
                <p className='text-xl font-medium uppercase text-right'>{qr.qty}</p>
            </div>
            <div>
                <p className='text-xs text-gray-500/80 uppercase mt-2 text-right'>Admitted</p>
                <p className='text-xl font-medium uppercase text-right'>{qr.admitted}</p>
            </div>
            
            <form className="w-full">
                <input name='admit' type='number' min={1} max={qr.qty-qr.admitted} placeholder='Admit ex.1' required/>
                <div>
                    <Button className='w-1/3 rounded-md'>Admit</Button>
                </div>
            </form>
        </div> */}
    

        <QRCode value={code} size={200}/>

        <p className='text-xs text-gray-500/80 uppercase mt-5'>Ticket Code</p>
        <p className='text-2xl font-medium uppercase'>{code.substring(0, 10)}</p>

        <p className='text-xs text-gray-500/80 uppercase mt-5'>Event</p>
        <p className='text-sm font-medium text-gray-600 capitalize'>{event.title}</p>
        <p className='text-xs text-gray-600 capitalize'>{newDate(event.secs)} @ {event.venue+' | '+event.start_at}</p>

        <p className='text-xs font-medium text-gray-700 mt-7'>
            Do not forget to come with your ticket as it will be used for verification at the point of entry.
        </p>
    </div>
  )
}

export default GetQRCode