import { createContext, useEffect, useState } from "react";
import { createEventDoc, createInfluencerDoc, getEventsDocs, getInfluencerDocs, successToast, updateEventDoc, updateInfluencerDoc } from "../utils/firebase/firebase.utils";
import axios from 'axios'
import useAuth from "../hooks/useAuth";


export const EventsContext = createContext({
    events: [],
    createEvent: () => {},
    updateEvent: () => {},
    getEvents: () => {},
    influencers: [], 
    addInfluencer: () => {}, 
    updateInfluencer: () => {}, 
    getInfluencers: () => {},
    tickets: [],
    getTickets: () => {},
});

export const EventsProvider = ({children}) => {
    const [ events, setEvents ] = useState([])
    const [ influencers, setinfluencers ] = useState([])

    // Get current user
    const { user } = useAuth();


    useEffect(() => {
        try {
            getEvents()
            getInfluencers()
        } catch (error) {
            console.log('Error..! ', error)
        }
    }, [])


    console.log('EventsContext Started..!')


    // Event
    const createEvent = async (docToAdd) => {
        await createEventDoc(docToAdd).then(
            getEvents()
        );
    }

    const updateEvent = async (docToUpdate, toastMsg) => {
        await updateEventDoc(docToUpdate, toastMsg).then(
            getEvents()
        );
    }

    const getEvents = async () => {
        const holdRec = await getEventsDocs(user)
        setEvents(holdRec)
    }


    // Influencer
    const addInfluencer = async (docToAdd) => {
        await createInfluencerDoc(docToAdd).then(
            getInfluencers()
        );
    }

    const updateInfluencer = async (docToUpdate, toastMsg) => {
        await updateInfluencerDoc(docToUpdate, toastMsg).then(
            getInfluencers()
        );
    }

    const getInfluencers = async () => {
        const holdRec = await getInfluencerDocs()
        setinfluencers(holdRec)
    }

    // const getTickets = async () => {
    //     const holdRec = await axios('')
    //     setinfluencers(holdRec)
    // }


    const value = {
        events, createEvent, updateEvent, getEvents,
        influencers, addInfluencer, updateInfluencer, getInfluencers,
        // tickets, getTickets
    }
    return (<EventsContext.Provider value={value}>{children}</EventsContext.Provider>);
}