
import React, { useContext, useEffect, useState } from 'react'
import './admin.styles.scss'
import '../../routes/other-styles.styles.css'
import { Button, Card, CardBody, Tooltip } from '@material-tailwind/react'
import NewEventForm from './new-event-form.components'
import Dialog01 from '../../components/dialog/dialog.components';
import { GrEdit } from 'react-icons/gr';
import { BsFileText, BsPlusCircle } from 'react-icons/bs';
import { LuFileEdit } from 'react-icons/lu'
import { TbUserPlus } from 'react-icons/tb'
import { GoTrash } from "react-icons/go";
import { Link } from 'react-router-dom'
import { EventsContext } from '../../context/events.context'
import { infoToast } from '../../utils/firebase/firebase.utils'
import { UserContext } from '../../context/user.context'

const NewEvent = () => {

    const { events, getEvents, updateEvent } = useContext(EventsContext)
    const [ eventsRecords, setEventsRecords ] = useState(events)
    const [ sendToDialog, setSendToDialog ] = useState([])
    const [ dialogState, setDialogState ] = useState(false);
    

    const { senderIDs, users } = useContext(UserContext)
    const [ curSenders, setCurSenders ] = useState(senderIDs)
    const [ allUsers, setAllUsers ] = useState(users)


    const createEvent = () => {
        setSendToDialog()
        setDialogState(Math.random())
    }

    const deleteEvent = (event) => {
      if (window.confirm("Click ok to confirm event deletion. Note: This action can't be undone")) {
        event['del'] = 'yes';
        const toastMsg = 'Event update successful';
        updateEvent(event, toastMsg);
      }
    }

    const processSend = (evt) => {
        setSendToDialog(evt)
        setDialogState(Math.random())
    }

    useEffect(() => {
        setEventsRecords(events)
        // console.log(events)
    }, [events])

    useEffect(() => {
        setAllUsers(users)
        console.log('Users = ', users)
    }, [users])

    useEffect(() => {
        setCurSenders(senderIDs)
        console.log('senderIDs = ', senderIDs)
    }, [senderIDs])

  return (
    <>
    <Card className='px-2 py-5 m-3'>
        <Button size='sm' variant='gradient' color='red' onClick={createEvent} className='w-36 mb-4'>
            <span className="float-left"><BsPlusCircle size={16} /></span>&nbsp; New Event
        </Button>
        {sendToDialog ?
        <Dialog01 declareState={dialogState} send={sendToDialog} body='New Event' icon={<LuFileEdit />} head='Update Event' />
        :<Dialog01 declareState={dialogState} body='New Event' icon={<BsFileText />} head='New Event' />
        }

        { curSenders ? 
            // <p>-- {allUsers.length} --</p>
            curSenders.map(el => {
            return (
                
            <p>-- {el.sid} --</p>
            )
        })
        :<p>Nothing Yet</p>}
        
        
        { eventsRecords ? eventsRecords.map(evt => evt.del != 'yes' ?
            <div key={evt.id} className={ evt.status === 'active'?'event-row-container':'event-row-container bg-red-100/70'}>
                <div className='ev-flyer-col'>
                    { evt.status === 'active' ?
                    <Link to={`/event/${evt.id}`}><img className="w-full rounded-l-md"
                        src={evt.flyer} alt="flyer"
                    /></Link>
                    :<img onClick={() => infoToast('Oops..! Inactive Event')} className="w-full rounded-l-md" src={evt.flyer} alt="flyer"/>
                    }
                </div>

                <div className='ev-details'>
                    <h1>{evt.title}</h1>
                    <h2>{newDate(evt.secs)}</h2>
                    <p>Host: {evt.host} &nbsp;<Link to={`/attendance/${evt.id}`}><span className='attendees'>{evt.atd_count ? evt.atd_count.no : 0}<b>{evt.atd_count ? evt.atd_count.yes :0}</b></span></Link></p>
                    <h4>{ evt.rate === 'free' ? evt.rate : evt.cur+' '+evt.rate }</h4>
                </div>

                <div className='ev-actions'>
                    <Tooltip content='Edit' className='tooltip-style'><button className='action-btn' onClick={() => processSend(evt)}><LuFileEdit size='12'/></button></Tooltip>
                    <Tooltip content='Add Influencer' className='tooltip-style'><button className='action-btn'><TbUserPlus size='12'/></button></Tooltip>
                    <Tooltip content='Delete' className='tooltip-style'><button className='del-btn' onClick={() => deleteEvent(evt)}><GoTrash size='12'/></button></Tooltip>
                </div>
            </div>
            :null
            )
        :<p>No Records Found</p>
        }

    </Card>
    </>
  )
}



export const processDate = (x) => {
    var newDate
    if (x.seconds) {
        newDate = new Date(x.seconds*1000) 
    } else {
        newDate = new Date(x*1000)
    }
    // var newDate = new Date(x.seconds*1000)
    var dtArray = newDate.split(" ")
    return dtArray[0]
}

export const processTime = () => {}

export const processSecs = (x) => {
    var dt = new Date(x);
    // add 1 day for accuracy
    dt = dt.setDate(dt.getDate() + 1);
    // dt = dt-57570000;
    dt = dt.toString()
    dt = dt.substring(0, 10)
    // dt = '98725878kljkfjl72834758'.substring(0, 10);
    return dt;
}

export const newDate = (x) => {
    var dt
    // if (x == '') {return 'Null'}
    if (x.seconds) {
        dt = new Date(x.seconds*1000) 
    } else {
        dt = new Date(x*1000)
    }
    dt = dt.toString()
    dt = dt.split(" ")
    dt = dt[0]+'. '+dt[1]+' '+dt[2]+', '+dt[3]
    return dt
}

export const newTime = (x) => {
    var dt = new Date(x.seconds*1000)
    dt = dt.toString()
    dt = dt.split(" ")
    dt = dt[4]
    return dt
}

export default NewEvent