

import React from 'react';
import './other-styles.styles.css';
import { Outlet } from 'react-router-dom';
import MyNavbar from '../components/navbar/navbar.components';

const NavigationPage = () => {
  return (
    <>
    {/* <div className='nav-components-container no-print'>
      <AdminNavbar />
      <MenuStrip />
    </div>
    <div className='general-container-wrapper'>
      <Outlet />
    </div> */}
    
    <div className="App">
      <MyNavbar />
      <div className="general-container-wrapper">
          <div className="general-container-size">
            <Outlet />
          </div>
      </div>
    </div>
    </>
  )
}

export default NavigationPage