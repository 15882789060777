
import React, { useContext, useEffect, useState } from 'react'
import { TbMessage, TbPhone, TbUserEdit } from 'react-icons/tb'
import { GiShoppingCart } from 'react-icons/gi'
import FormTextarea from '../../components/form/textarea.component'
import FormInput from '../../components/form/forminput.component';
import { Button, Card, DialogFooter } from '@material-tailwind/react';
import { IoTicketOutline } from 'react-icons/io5';
import GetQRCode from '../../components/qrcode.components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EventsContext } from '../../context/events.context';
import html2canvas from 'html2canvas';
import { FaCircleCheck, FaRegCircleCheck } from 'react-icons/fa6';
import { TiWarning } from 'react-icons/ti';
import { apiUrl, errorToast, infoToast, successToast } from '../../utils/firebase/firebase.utils';
import axios from 'axios'
import { SendSMS } from '../admin/msg-broadcast.components';


const GetTicket = () => {
    const { key } = useParams();
    const navigate = useNavigate()
    const { events, getEvents } = useContext(EventsContext)
    const [ event, setEvent ] = useState();

    const fieldValues = {
        fname: '',
        sname: '',
        phone: '',
        email: '',
        evt_id: key,
        ticket_code: '',
        qty: '',
        status: 'yes',
        ticket: 'yes',
        admitted: '0',
        reference: 'free'
    }

    // localStorage.setItem('steps', '1');
    const [ formfields, setFormFields ] = useState(fieldValues)
    const [ dialogState, setDialogState ] = useState();
    const [ confirm, setConfirm ] = useState(false);
    const [ step1, setStep1 ] = useState(false);
    const [ steps, setSteps ] = useState(1);


    const reconfirm = () => setConfirm(true);
    const getRandom = () => {
        const y = (Math.random() + 1).toString(36).substring(2);
        return y.toUpperCase();
    }

    const findEvent = () => {
        const evt = events.find(ev => ev.id === key);
        // console.log('evtents2 ', events)
        // console.log('key ', key)
        // console.log('evt ', evt)
        setEvent(evt);
        // console.log('event', event)
        // console.log('Key: ', key)
        // return console.log('evt', evt)
        // if (!evt) {
        //     return navigate('/events/'+key)
        // }
        if (event) {
            if (evt.rate != 'free') {
                formfields['reference'] = 'ticketed';
            }

            if (evt.rate == 'free' && steps < 3) {
                // formfields = [...formfields, evt_id = evt.id]
                formfields['reference'] = 'free';
                localStorage.setItem('steps', '2');
                setSteps(2)
                // }else{
                //     localStorage.setItem('steps', '1');
            }
            // console.log(evt)
        }
    }
    
    // console.log('steps', steps)
    const saveToLara = async() => {
        
        var evt = event;
        if (event) {}else{
            const evtt = events.find(ev => ev.id === key);
            setEvent(evtt);
            evt = evtt;
        }
        // const text = 'Hello '+formfields.fname+', your ticket code to '+evt.title+' is '+formfields.ticket_code
        // SendSMS(formfields.phone, text)
        // // console.log('event', event)
        // return
        // console.log(evt)
            try {
                const saveRec = await axios.post(apiUrl()+'/api/addticket', formfields)
                const feedback = await axios.post(apiUrl()+'/api/addcontact', formfields)
                console.log(feedback?.data.result)
                successToast(saveRec?.data.result)
                const text = 'Hello '+formfields.fname+', your ticket code to '+evt.title+' is '+formfields.ticket_code
                SendSMS(formfields.phone, text)
                // return
                setSteps(3)
                localStorage.setItem('steps', '3');
            } catch (error) {
                errorToast('Oops..! Unable to purchase ticket')
                console.log("Something happened "+error)
                return
            }
        // } else {
        //     infoToast('Event not found')
        // }
    }
    
    const clearForm = async() => {
        localStorage.removeItem('steps')
        localStorage.removeItem('locAttd')
        setSteps(3)
        setFormFields(fieldValues)
        // navigate("/tickets/"+key)
        successToast('Repurchase Ticket')
        window.location.reload();
        return 
    }

    const handleResponse = (event) => {
        event.preventDefault()

        localStorage.setItem('locAttd', JSON.stringify(formfields));
        // console.log(formfields)

        // Save to DB
        saveToLara()
        // clearForm()

        // setConfirm(true)
        // setSteps(3)
        // localStorage.setItem('steps', '3');
        
        // const atdResponse = {
        //     fname: event.target.fname.value,
        //     sname: event.target.sname.value,
        //     phone: event.target.phone.value,
        //     email: event.target.email.value,
        //     msg: event.target.msg.value,
        //     status: attend.disable
        // }
    }

    const handleNext = () => {
        localStorage.setItem('steps', '2');
        setSteps(2)
    }

    const handleQRdownload = async () => {
        // return saveToLara();
        const element = document.getElementById('qr_div'),
        canvas = await html2canvas(element),
        data = canvas.toDataURL('image/jpg'),
        link = document.createElement('a');
     
        link.href = data;
        link.download = 'ticket_'+formfields.ticket_code.substring(0,5)+'.jpg';
     
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        successToast("Find ticket in your recent downloads/photos")
    };

    
    const handleOnchange = (event) => {

        const { name, value } = event.target;
        setFormFields({...formfields, [name]: value, 'ticket_code': getRandom()})
        // console.log(value)
        // console.log(formfields)
        // console.log("random", y.toUpperCase());
    }

        // localStorage.removeItem('locAttd');
        // localStorage.setItem('steps', '1');
        // console.log(steps)

    useEffect(() => {
        // findEvent()
        var locSteps = localStorage.getItem('steps');
        if (locSteps) {  
            setSteps(locSteps)
            // console.log(locSteps)
        }

        var locAttd = localStorage.getItem('locAttd');
        // return console.log(locAttd)
        if (locAttd) {  
            locAttd = JSON.parse(localStorage.getItem('locAttd'));
            locAttd['evt_id'] = key;
            // locAttd['ticket_code'] = locAttd.evt_code;
            // localStorage.setItem('locAttd', JSON.stringify(locAttd));
            // locAttd = fieldValues.concat(locAttd)
            setFormFields(locAttd)
            // console.log(locAttd)
            // console.log('locAttd', locAttd)
        }
    },[]);


    useEffect(() => {
        findEvent()
        // console.log(events)
        // console.log(formfields)
    },[events]);


  return (
    <Card className='mx-2 mt-2 mb-12 px-2 py-5'>
        <div className='ticket-header'>
            <h2><span className='text-left'><IoTicketOutline size={32} /></span>&nbsp; Get Ticket</h2>
            <p>Provide details to proceed</p>
        </div>
        <div className='steps'>
            <div className={steps == 1 ? 'step1 bg-red-300/50' : 'step1'}><p>{steps > 1 ? <FaRegCircleCheck size={16} className='float-left' />:null}&nbsp; Step 1</p></div>
            <div className={steps == 2 ? 'step2 bg-yellow-300/30' : 'step2'}><p>{steps > 2 ? <FaRegCircleCheck size={16} className='float-left' />:null}&nbsp; Step 2</p></div>
            <div className={steps == 3 ? 'step3 bg-green-300/50' : 'step3'}><p>{steps == 3 ? <FaRegCircleCheck size={16} className='float-left' />:null}&nbsp; Step 3</p></div>
        </div>
        { steps == 1 ? 
        <form className="gen-form" onSubmit={handleNext} method='post'>
            <FormInput name='reference' onChange={handleOnchange} value={formfields.reference ? formfields.reference :'free'} type='text' size='md' label='Reference' className='m-2' inIcon={<TiWarning />} required/>
            {/* <FormInput name='phone' onChange={handleOnchange} value={formfields.phone} type='number' max='10' size='md' label='Phone' min='1' className='m-2' inIcon={<TbPhone />} required/> */}
            
            <DialogFooter>
                <Button type='submit' size='sm' color="yellow">
                    <span>Next</span>
                </Button>
            </DialogFooter>
        </form>
        :null}
        { steps == 2 ? 
        <form className="gen-form" onSubmit={handleResponse} method='POST'>
            <FormInput name='fname' onChange={handleOnchange} value={formfields.fname} type='text' size='md' label='Firstname' className='m-2' inIcon={<TbUserEdit />} required/>
            <FormInput name='sname' onChange={handleOnchange} value={formfields.sname} type='text' size='md' label='Last Name' className='m-2' inIcon={<TbUserEdit />} required/>
            <FormInput name='phone' onChange={handleOnchange} value={formfields.phone} type='number' size='md' label='Phone' min='1' maxLength='10' className='m-2' inIcon={<TbPhone />} required/>
            <FormInput name='email' onChange={handleOnchange} value={formfields.email} type='email' size='md' label='Email' className='m-2' inIcon={<TbMessage />} />
            <FormInput name='qty' onChange={handleOnchange} value={formfields.qty} type='number' size='md' label='Quantity' min='1' className='m-2' inIcon={<GiShoppingCart />} required/>

            <DialogFooter>
                <Button type='submit' size='sm' variant="gradient" color="green">
                    <span>Confirm</span>
                </Button>
            </DialogFooter>
        </form>
        :null}
        { steps == 3 && event ? 
        <>
            <div className='succes_tag'>
                {/* <p className='text-center'><FaRegCircleCheck size={30} /></p> */}
                <p>
                    Ticket purchase successful. Kindly save and present ticket at checkpoint or click&nbsp;
                    <span onClick={clearForm}>HERE</span> to buy new tickets.
                </p>
            </div>
            <div id='qr_div' className='qr-container mt-1'>
                <GetQRCode code={formfields.ticket_code} event={event} />
            </div>
            <Button className='m-2' onClick={handleQRdownload}><IoTicketOutline size={18} className='float-left' /> Save Ticket To Gallery</Button>
        </>
        :null}
    </Card>
  )
}

export default GetTicket