
import React, { useContext, useEffect, useState } from 'react';
import './user.styles.scss';
import '../../routes/other-styles.styles.css';
import { Button, Card, CardBody, Input } from '@material-tailwind/react';
import { VscSaveAs } from "react-icons/vsc";
import { PiBuildingsBold } from 'react-icons/pi';
import { MdOutlineMail, MdShortText } from 'react-icons/md';
import { TbCurrentLocation, TbCurrentLocationOff, TbGlobe } from 'react-icons/tb';
import { TiPhoneOutline } from 'react-icons/ti';
import XformInput from '../../components/form/forminput.component';
import { BsCheckCircle } from 'react-icons/bs';
import { CiLock } from "react-icons/ci";
import { HiOutlineLockClosed } from 'react-icons/hi';
import { FaTimes } from 'react-icons/fa';
import { BiSave } from 'react-icons/bi';
import useAuth from '../../hooks/useAuth';
import { UserContext } from '../../context/user.context';
import { IoKeyOutline } from 'react-icons/io5';
import { createSender, successToast } from '../../utils/firebase/firebase.utils';

const UserSettings = () => {
  var c = 1;
  const cur_date = new Date();
  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
//   const { currentUser } = useAuth();
    const { currentUser, company } = useContext(UserContext)
    const [ companyDet, setCompanyDet ] = useState(currentUser)
    const [ curUser, setCurUser ] = useState(currentUser)
//   const { displayName, email, phone } = curUser;

    const [newSenderId, setNewSenderID] = useState("");
    const onChangeSID = ({ target }) => {
        setNewSenderID(target.value);
        // console.log(target.value)
    }


    const AddSenderId = async () => {
        const docToAdd = {
            sid: newSenderId
        }

        await createSender(docToAdd).then(
            successToast(newSenderId+" Sender ID successfully added")
        )
    }


  const handleOpen = () => setOpen(!open);
  const toggleUpdate = () => setUpdate(!update);


  const handleSubmitCompany = async (event) => {
    event.preventDefault();
  }


  const handleSubmitRegistryCode = async (event) => {
    event.preventDefault();
    const { target } = event;

    return alert(target.user_email.value);
  }


//   useEffect(() => {
//     setCurUser(currentUser)
//   },[currentUser])

  useEffect(() => {
    setCompanyDet(company)
  },[company])



  
  return (
    <>
      <div className='profile-container general-container-size'> 
      
        <div className='profile-img-cont'>
            {/* <img className='w-full rounded-t-xl' src="https://t4.ftcdn.net/jpg/05/22/65/47/360_F_522654750_dWty6iO9Ev0Xjqlmorg6QV04sgKpmkUN.jpg" alt="" /> */}
        </div>

        <div className="sid-container">
            <p className='blue-text'>Add new Sender ID's here</p>
            <div className="relative flex w-full">
                <Input
                    type="email"
                    label="Sender ID"
                    value={newSenderId}
                    onChange={onChangeSID}
                    className="pr-20"
                    containerProps={{
                    className: "min-w-0",
                    }}
                />
                <Button
                    size="sm"
                    color={newSenderId ? "teal" : "blue-gray"}
                    disabled={!newSenderId}
                    className="!absolute right-1 top-1 rounded"
                    onClick={AddSenderId}
                >
                    &nbsp;Add&nbsp;
                </Button>
            </div>
            
            <p className='blue-text pt-6'>Update other system requirements here</p>
        </div>

        
        <form id='' onSubmit={handleSubmitCompany} className='mb-4 pb-4'>
            <div className='items-input-group flex'>
            <XformInput inIcon={<IoKeyOutline />} value={companyDet?.api} className='w-full' name='api' type='text' size='lg' label='SMS API' required/>
            </div>
            
            {/* 
            <XformInput inIcon={<PiBuildingsBold />} value={companyDet?.api} className='w-full' name='api' type='text' size='lg' label='SMS API' required/>
            </div>
            
            <div className='items-input-group flex'>
            <XformInput inIcon={<TiPhoneOutline />} className='w-full' name='phone' type='number' min='0' size='lg' label='Phone' required/>
            </div>
            
            <div className='items-input-group flex'>
            <XformInput inIcon={<MdOutlineMail />} className='w-full' name='email' type='email' min='0' size='lg' label='Email' readOnly/>
            </div>
            
            <div className='items-input-group flex'>
            <XformInput inIcon={<HiOutlineLockClosed />} className='w-full' name='password' type='password' size='lg' label='Password'/>
            </div>
            
            <div className='items-input-group flex'>
            <XformInput inIcon={<HiOutlineLockClosed />} className='w-full' name='password_confirm' type='password' size='lg' label='Confirm Password'/>
            </div> */}

            <hr className="my-2 border-blue-gray-50" />

            <div className='items-input-group'>
            <Button type='submit' className='float-right m-1'>&nbsp;&nbsp;<BiSave size='18' className='float-left'/>&nbsp;&nbsp;<span>Update Details</span>&nbsp;&nbsp;</Button>
            </div>
            <p className='my-10'>&nbsp;</p>
        </form>



        {/* { currentUser ? 
            <div className='profile-text'>
                <h2>{displayName}</h2>
                <p>{email}</p>
                <p>{phone}</p>
                {
                    update === false ? 
                    <Button onClick={toggleUpdate} type='button' className='float-center m-4' size='sm' variant="outlined">&nbsp;<VscSaveAs size='18' className='float-left mr-2'/> Update Profile &nbsp;</Button>
                    : <Button onClick={toggleUpdate} type='button' className='float-center m-4' size='sm' variant="outlined">&nbsp;<VscSaveAs size='18' className='float-left mr-2'/> Cancel &nbsp;</Button>
                }
            </div>
        :null}

        {
            update === true ? 
            <form id='' onSubmit={handleSubmitCompany}>
                <div className='items-input-group flex'>
                <XformInput inIcon={<PiBuildingsBold />} value={currentUser?.displayName} className='w-full' name='name' type='text' size='lg' label='Name' required/>
                </div>
                
                <div className='items-input-group flex'>
                <XformInput inIcon={<TiPhoneOutline />} className='w-full' name='phone' type='number' min='0' size='lg' label='Phone' required/>
                </div>
                
                <div className='items-input-group flex'>
                <XformInput inIcon={<MdOutlineMail />} className='w-full' name='email' type='email' min='0' size='lg' label='Email' readOnly/>
                </div>
                
                <div className='items-input-group flex'>
                <XformInput inIcon={<HiOutlineLockClosed />} className='w-full' name='password' type='password' size='lg' label='Password'/>
                </div>
                
                <div className='items-input-group flex'>
                <XformInput inIcon={<HiOutlineLockClosed />} className='w-full' name='password_confirm' type='password' size='lg' label='Confirm Password'/>
                </div>

                <hr className="my-2 border-blue-gray-50" />

                <div className='items-input-group'>
                <Button type='submit' className='float-right m-1'>&nbsp;&nbsp;<BiSave size='18' className='float-left'/>&nbsp;&nbsp;<span>Save Details</span>&nbsp;&nbsp;</Button>
                </div>
                <p className='my-10'>&nbsp;</p>
            </form>
            :null
        } */}
          
      </div>
    </>
  )
}

export default UserSettings 