
import React, { useContext, useEffect, useState } from 'react'
import './admin.styles.scss'
import '../../routes/other-styles.styles.css'
import { Button, Card, CardBody, Tooltip, Typography } from '@material-tailwind/react'
import NewEventForm from './new-event-form.components'
import Dialog01 from '../../components/dialog/dialog.components';
import { GrEdit } from 'react-icons/gr';
import { BsFileText, BsPlusCircle } from 'react-icons/bs';
import { LuFileEdit } from 'react-icons/lu'
import { TbUserPlus } from 'react-icons/tb'
import { AiFillLike, AiOutlineLike } from "react-icons/ai";
import { Link, useNavigate, useParams } from 'react-router-dom'
import { EventsContext } from '../../context/events.context'
import { infoToast } from '../../utils/firebase/firebase.utils'
import { BiDislike } from 'react-icons/bi'

const EventAttendance = () => {
    const { key } = useParams();
    const navigate = useNavigate();

    const { events, getEvents, updateEvent } = useContext(EventsContext)
    const [ curEvt, setCurEvt ] = useState()
    const [ Attd, setAttd ] = useState()
    // const [ eventsRecords, setEventsRecords ] = useState(events)


    const evSearchFunc = () => {
        var Atd = 0;
        const evSearch = events.find(el => el.id === key);
        
        if (evSearch) {
            if (evSearch.status != 'active') {
                infoToast('Event Inactive')
                console.log('Event Inactive')
                window.location.replace('/events')
            }
            if (evSearch.attendance.length > 0) {
                Atd = evSearch.attendance.filter(el => el.status != 'no')
                Atd = Atd.length
                // .reduce((total, item) => total + item.total, 0)
            }
            setAttd(Atd)
        }else{
            window.location.replace('/events')
        }
        setCurEvt(evSearch)
    }

    useEffect(() => {
        evSearchFunc()
    }, [])


  return (
    <>
    <Card className='px-2 py-5 m-3'>
        
        { curEvt ? 
        <>
            <div className='attendance-header p-2'>
                <Typography variant='h5'>{curEvt.title}</Typography>
                <p>Total: {curEvt.attendance.length} / Attending: {Attd}</p>
            </div>

            {
                curEvt.attendance.length > 0 ?
                    curEvt.attendance.map((el, index) => 
                        <div key={index} className='attendance-body p-2'>
                            <p><span className='font-semibold'>{el.fname+' '+el.sname}</span> - {el.phone}</p>
                            <h4>{el.msg} <br/>
                            {
                                el.status === 'no' ?
                                <span className='text-gray-400'><BiDislike size={16} className='float-left text-pink-500' />&nbsp; {el.fname} can't make it</span>
                                :    
                                <span className='text-gray-400'><AiOutlineLike size={16} className='float-left text-green-500' />&nbsp; {el.fname} is coming</span>
                            }
                            </h4>
                        </div>
                    )
                :
                <div className='attendance-body p-2'><h4>No Attendance Info Found</h4></div>
            }

            {/* <div className='attendance-body p-2'>
                <p><span className='font-semibold'>{curEvt.attendance.fname+' '+curEvt.attendance.sname}</span> - {curEvt.attendance.phone}</p>
                <h4>{curEvt.attendance.msg} <br/>
                    <span className='text-gray-400'><AiOutlineLike size={16} className='float-left text-green-500' />&nbsp; Julia is coming</span>
                </h4>
            </div>

            <div className='attendance-body p-2'>
                <p><span className='font-semibold'>Kingsley Parker</span> - 001243728233</p>
                <h4>Messa goes Here Messa goes Here essa goes Here Messa goes Here essa goes Here Messa goes <br/>
                    <span className='text-gray-400'><BiDislike size={16} className='float-left text-pink-500' />&nbsp; Kingsley can't make it</span>
                </h4>
            </div> */}
        </>
        :null}
    </Card>
    </>
  )
}

export default EventAttendance