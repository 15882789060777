

import React, { useContext, useEffect, useState } from 'react'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Textarea,
    Spinner,
} from "@material-tailwind/react";
import './dialog.styles.scss'
import FormInput from '../form/forminput.component';
import { TbMessage, TbPhone, TbUserEdit } from 'react-icons/tb';
import FormTextarea from '../form/textarea.component';
import NewEventForm from '../../routes/admin/new-event-form.components';
import { BsFileText, BsSend } from 'react-icons/bs';
import NewInfluencerForm from '../../routes/admin/new-influencer-form.components';
import { EventsContext } from '../../context/events.context';
import axios from 'axios';
import useAuth from '../../hooks/useAuth';
import { apiUrl } from '../../utils/firebase/firebase.utils';



const Dialog01 = ({ declareState, head, icon, body, send, curEvent }) => {

    const attendAction = {
        header: head,
        text: 'Let us know if you are coming',
        disable: '',
        display: false
    }
    
    const { curUser } = useAuth();
    const { events, createEvent, updateEvent } = useContext(EventsContext)
    const [attend, setAttend] = useState(attendAction);
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        if (open == true) {
            setAttend(attendAction)
            setOpen(!open);
        } else {
            setOpen(!open);
        }
    }
    const handleChoiceYes = () => setAttend({...attend, disable: 'yes', display: true, header: "Hurray..!", text: "We can't wait to see you"});
    const handleChoiceNo = () => setAttend({...attend, disable: 'no', display: true, header: "Oops..!", text: "Sorry you can't make it"});


    const handleResponse = async (event) => {
        event.preventDefault();

        var holdArr = [];
        const atdResponse = {
            fname: event.target.fname.value,
            sname: event.target.sname.value,
            phone: event.target.phone.value,
            email: event.target.email.value,
            msg: event.target.msg.value,
            qty: '',
            status: attend.disable,
            ticket: 'no'
        }
        localStorage.setItem('locAttd', JSON.stringify(atdResponse))
        try {
            var feedback = await axios.post(apiUrl()+'/api/addcontact', atdResponse)
            feedback = feedback?.data.feedback;
            console.log(feedback)
        } catch (error) {
            console.log('Error.. : ', error)
        }
        
        // holdArr = curEvent.attendance
        // curEvent['attendance'] = {...curEvent.attendance, atdResponse};
        var curAtd = curEvent.attendance
        if (curAtd.length > 0) {
            curAtd.map(el => el != '' ? holdArr.push({...el}) :null)
        }
        holdArr.push({...atdResponse, status: attend.disable})
        curEvent['attendance'] = holdArr;
        
        const yesAtd = holdArr.filter(el => el.status === 'yes') 
        const noAtd = holdArr.filter(el => el.status === 'no') 
        const atd_count = {
            yes: yesAtd.length,
            no: noAtd.length
        }
        curEvent['atd_count'] = atd_count;
        var toastMsg = 'Event update successful';
        if (attend.disable == 'yes') {
            toastMsg = "Hurray..! We can't wait to see you"
        } else {
            toastMsg = "Sorry you can't make it. We hope to see you another time"
        }
        updateEvent(curEvent, toastMsg)
        handleOpen()

        // console.log(curEvent)
    }
    // console.log(JSON.parse(localStorage.getItem('locAttd')))


    useEffect(() => {
        if (declareState != '') {
            handleOpen()
        }
    },[declareState]);


  return (
    <>
      <Dialog open={open} handler={handleOpen}>
        <div className='dialog-header-container'>
            { body === 'ATD' ?
                <div className='atd'>
                    <h1>{attend.header}</h1>
                </div>
                // : body === 'New Event' ?
                //     <div className='new-event'>
                //         <BsFileText className='text-red-500' /> &nbsp;&nbsp;
                //         <h2>{head}</h2>
                //     </div>
                : 
                <div className='new-event'>
                    <p className='text-red-500'>{icon}</p> &nbsp;&nbsp;
                    <h2>{head}</h2>
                </div>
            }
        </div>
        
        <DialogBody className='dialog-container'>
            { body === 'ATD' ?
            <div className='atd'>
                <p>{attend.text}</p>
                <div className='flex'>
                    <Button className='m-1' variant={attend.disable == 'yes' ? "outlined" : "gradient"} color="green" onClick={handleChoiceYes}>
                        <span>&nbsp;Yes&nbsp;</span>
                    </Button>
                    <Button className='m-1' variant={attend.disable == 'no' ? "outlined" : "gradient"} color="red" onClick={handleChoiceNo}>
                        <span>&nbsp;No&nbsp;</span>
                    </Button>
                </div>

                <p>&nbsp;</p>

                { attend.display === true ? 
                <form className="gen-form" onSubmit={handleResponse}>
                    <FormInput name='fname' type='text' size='md' label='Firstname' className='m-2' inIcon={<TbUserEdit />} required/>
                    <FormInput name='sname' type='text' size='md' label='Last Name' className='m-2' inIcon={<TbUserEdit />} />
                    <FormInput name='phone' type='number' size='md' label='Phone' min='0' maxLength='10' className='m-2' inIcon={<TbPhone />} required/>
                    <FormInput name='email' type='email' size='md' label='Email' className='m-2' inIcon={<TbMessage />} />
                    <FormTextarea name='msg' type='text' maxLength='50' size='lg' label='Message to host (Optional)' className='m-2'/>

                    <DialogFooter>
                        <Button type='submit' variant="gradient" color="green">
                            <span>Confirm</span>
                        </Button>
                    </DialogFooter>
                </form>
                :null}

            </div>

            : body === 'New Event' ?
                <>
                <NewEventForm send={send} user={curUser}/>
                </>
            : body === 'influencer' ?
                <>
                <NewInfluencerForm send={send} user={curUser}/>
                </>
            : body === 'LOAD' ?
                // <div className="atd">
                    <div className='loader m-4 p-2 flex'>
                        <Spinner className='float-left' />
                        <p>&nbsp; Pleas Wait..! Dialog will automatically close when sending is complete.</p>
                    </div>
                // </div>
            :null }
        </DialogBody>

        {/* <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="green" onClick={handleOpen}>
            <span>Confirm</span>
          </Button>
        </DialogFooter> */}

      </Dialog>
    </>
  )
}

export default Dialog01