
import { Button, Card, Input } from '@material-tailwind/react'
import React, { useContext, useEffect, useState } from 'react'
import { IoTicketOutline } from 'react-icons/io5';
import { LuFormInput } from 'react-icons/lu';
import { apiUrl, errorToast, infoToast, successToast } from '../../utils/firebase/firebase.utils';
import axios from 'axios'
import { EventsContext } from '../../context/events.context';
import GetQRCode from '../../components/qrcode.components';


const EventCheckout = () => {
    
    const [ pages, setPages ] = useState(1);
    const [check, setCheck] = useState("");
    const [ticket, setTicket] = useState();
    const { events, getEvents } = useContext(EventsContext)
    const evtDetails = {
        title: "Event Checkout",
        subtitle: "Provide Event ID to Proceed"
    }
    const [ curEvent, setCurEvent] = useState();
    const [ eventTitle, setEventTitle] = useState(evtDetails);
    const [admit, setAdmit] = useState("");
    const [ qr, setQr ] = useState()
    const [ scan, setScan ] = useState("");
    const onChange = ({ target }) => setCheck(target.value);

    const onScanChange = ({ target }) => {
        const val = target.value.toUpperCase()
        // console.log(qr)
        // return console.log(val)
        if (val != '' || val != null) {
            setScan(val)
            findTicket(val)
        }
    };

    const onAdmitChange = ({ target }) => {
        const val = target.value;
        const x = qr.qty-qr.admitted;
        if (val < 1) {
            return infoToast('Oops..! Cannot admit less than 1')
        }
        if (val > x) {
            return infoToast('Oops..! Cannot admit more than '+x+' entries')
        }
        setAdmit(val)
    };
    

    const handleAdmit = async (e) => {
        e.preventDefault()
        // console.log(admit)
        
        const admitVal = (+qr.admitted) + (+admit);
        qr['admitted'] = admitVal
        // console.log(admitVal)
        // return console.log(qr)
        const putTicket = await axios.put(apiUrl()+'/api/putticket/'+qr.id, qr)
        if (putTicket) {
            successToast('Admission Successful')
            setScan("")
        }
        // console.log(putTicket)
        setAdmit("")
    }

    const handleCheck = () => {
        try {
            var fetchRec = events.find(ev => ev.id === check)
            // console.log(events)
            // console.log(check)
            // console.log(fetchRec)

            if (fetchRec) {
                console.log('Event Exists')
                successToast('Checked..!')
                eventTitle['title'] = fetchRec.title
                eventTitle['subtitle'] = 'Scan Ticket or Type ID to Proceed'
                localStorage.setItem('check', check)
                localStorage.setItem('pages', 2)
                localStorage.setItem('eventTitle', JSON.stringify(eventTitle))
                setPages(2)
                setCurEvent(fetchRec)
            } else {
                errorToast('Oops..! Wrong Event ID Entered')
                console.log('Wrong ID Entered')
            }
        } catch (error) {
            
        }
    }

    const findTicket = async (scanVal) => {
        try {
            // console.log('curEvent', curEvent)
            var fetchRec = await axios(apiUrl()+'/api/gettickets/'+curEvent.id)
            // console.log('scanVal', scanVal)
            // console.log('fetchRec', fetchRec)
            fetchRec = fetchRec.data.result

            if (fetchRec.length > 0) {
                console.log(fetchRec)
                const fetchTicket = fetchRec.find(evt => evt.ticket_code.toUpperCase() === scanVal)
                if (fetchTicket) {
                    successToast('Tiket Found')
                    setQr(fetchTicket)
                } else {
                    infoToast('Oops..! No Ticket Found')
                    setQr()
                }
                // successToast('Checked..!')
                // eventTitle['title'] = fet
                // eventTitle['subtitle'] = 'Scan Ticket or Type ID to Proceed'
                // setPages(2)
            } else {
                errorToast('Oops..! Ticket not found')
                console.log('Ticket not found')
            }
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    const handleTicketCheck = (e) => {
        e.preventDefault()
        // successToast(scan)
        // return
        findTicket(scan)
    }


    // localStorage.setItem('pages', 1)
    // localStorage.removeItem('eventTitle')


    useEffect(() => {   
        var locPages = localStorage.getItem('pages');
        if (locPages) {  
            setPages(locPages)
            // console.log(locPages)
        }

        var locCheck = localStorage.getItem('check');
        if (locCheck) {  
            setCheck(locCheck)
        }

        var locEvTitle = localStorage.getItem('eventTitle');
        if (locEvTitle) {  
            locEvTitle = JSON.parse(localStorage.getItem('eventTitle'));
            setEventTitle(locEvTitle)
        }
    },[]);


    useEffect(() => {   
        setCurEvent(events.find(evt => evt.id === check))
    },[events])


  return (
    
    <Card className='mx-2 mt-2 mb-12 px-2 py-5'>
        <div className='ticket-header'>
            <h2><span className='text-left'><IoTicketOutline size={32} /></span>&nbsp; {eventTitle.title}</h2>
            <p>{eventTitle.subtitle}</p>
        </div>
        { pages == 1 ? 
        <form className="gen-form">
            <div className="relative flex w-cal[100%-8px] m-2">
                <Input
                    type="text"
                    label="Event ID"
                    value={check}
                    onChange={onChange}
                    className="pr-20"
                    containerProps={{
                    className: "min-w-0",
                    }}
                />
                <Button
                    size="sm"
                    color={check ? "gray" : "blue-gray"}
                    disabled={!check}
                    className="!absolute right-1 top-1 rounded"
                    onClick={handleCheck}
                >
                    Check
                </Button>
            </div>
        </form>
        :null}
        
        { pages == 2 ? 
        <>
            <form className="gen-form" onSubmit={scan ? handleTicketCheck :null}>
                <div className="relative flex w-cal[100%-8px] m-2">
                    <Input
                        type="text"
                        label="Event ID"
                        value={scan}
                        onChange={onScanChange}
                        className="pr-20"
                        containerProps={{
                        className: "min-w-0",
                        }}
                    />
                    <Button
                        size="sm"
                        variant='gradient'
                        color={scan ? "pink" : "pink"}
                        disabled={!scan}
                        className="!absolute right-1 top-1 rounded"
                        onClick={scan ? handleTicketCheck :null}
                    >
                        Search
                    </Button>
                </div>
            </form>
        </>
        :null}

        { qr != null && qr.qty != qr.admitted ?
        <>
            {/* <div className='succes_tag'>
                <p>Use this pannel to admit entry</p>
            </div> */}
            <div className='rounded-md m-2 p-3 flex bg-gray-100'>
                <div className='w-1/3 pt-1'>
                    <p className='text-xs text-gray-500/80 uppercase text-left'>Tickets /&nbsp;
                        <span className='text-sm text-gray-700 font-medium'>{qr.qty}</span>
                    </p>
                    <p className='text-xs text-gray-500/80 uppercase text-left'>Admitted /&nbsp;
                        <span className='text-sm text-gray-700 font-medium'>{qr.admitted}</span>
                    </p>
                </div>
                <form className="w-2/3">
                    <div className="relative flex w-full m-2">
                        <Input
                            min={1}
                            max={qr.qty-qr.admitted}
                            type="number"
                            label="Admit"
                            value={admit}
                            onChange={onAdmitChange}
                            className="pr-20"
                            containerProps={{
                            className: "min-w-0",
                            }}
                        />
                        <Button
                            size="sm"
                            color={admit ? "gray" : "blue-gray"}
                            disabled={!admit}
                            className="!absolute right-1 top-1 rounded"
                            onClick={handleAdmit}
                        >Admit
                        </Button>
                    </div>
                </form>
            </div>
            <div id='qr_div' className='qr-container mt-1'>
                <GetQRCode code={qr.ticket_code} event={curEvent} qr={qr} />
            </div>
        </>
        :qr != null && qr.qty === qr.admitted ?
            <div className='succes_tag'>
                <p>
                    Ticket has already been used. Thank You!
                </p>
            </div>
        :null}
        
        {/* { steps == 3 && event ? 
        <>
            <div className='succes_tag'>
                <p>
                    Ticket purchase successful. Kindly save and present ticket at checkpoint or click&nbsp;
                    <span onClick={clearForm}>HERE</span> to buy new tickets.
                </p>
            </div>
            <div id='qr_div' className='qr-container mt-1'>
                <GetQRCode code={formfields.ticket_code} event={event} />
            </div>
            <Button className='m-2' onClick={handleQRdownload}><IoTicketOutline size={18} className='float-left' /> Save Ticket To Gallery</Button>
        </>
        :null} */}
    </Card>
  )
}

export default EventCheckout