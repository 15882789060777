import logo from './logo.svg';
import './App.scss';
import './routes/other-styles.styles.css'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Tooltip,
  IconButton,
} from "@material-tailwind/react";
import InvDisplay from './routes/inv-display/inv-display.components';
import MyNavbar from './components/navbar/navbar.components';
import NewEvent from './routes/admin/new_event.components';
import { Route, Routes } from 'react-router-dom';
import NavigationPage from './routes/navigation.components';
import NewInfluencer from './routes/admin/new-influencer.components';
import MsgBroadcast from './routes/admin/msg-broadcast.components';
import { Toaster, toast } from 'sonner';
import HomeIndex from './routes/homepage/home-index.components';
import LoginPage from './routes/auth/login.components';
import UserProfile from './routes/user/user-settings.components';
import RequireAuth from './components/RequireAuth';
import EventAttendance from './routes/admin/event-attendance.components';
import GetTicket from './routes/homepage/home-tickets.components';
import EventCheckout from './routes/admin/checkout.components';
import Homepage from './routes/home/homepage.components';
import ContactsPage from './routes/admin/contact.components';
import UserSettings from './routes/user/user-settings.components';
import NewNavbar from './components/new_navbar/new_navbar.components';
import TrialCheck from './routes/admin/trial.components';

function App() {
  return (
    // <div className="App">
    //   <MyNavbar />
    //   <div className="general-container-wrapper">
    //       <div className="general-container-size">
    //         <InvDisplay />
    //         <NewEvent />
    //       </div>
    //   </div>
    // </div>
    <>
    <Routes>
      {/* <Route path='/' element={<NewNavbar />}> */}
      <Route path='/' element={<NavigationPage />}>
        {/* <Route index element={<HomeIndex />} /> */}
        <Route index element={<Homepage />} />
        <Route path='/tickets/:key' element={<GetTicket />} />
        <Route path='/event/:key' element={<InvDisplay />} />
        <Route element={<RequireAuth allowedRoles='user'/>}>
          <Route path='/events' element={<NewEvent />} />
          <Route path='/attendance/:key' element={<EventAttendance />} />
          <Route path='/influencers' element={<NewInfluencer />} />
          <Route path='/broadcast' element={<MsgBroadcast />} />
          {/* <Route path='/profile' element={<UserProfile />} /> */}
          <Route path='/checkout' element={<EventCheckout />} />
          <Route path='/contacts' element={<ContactsPage />} />
          <Route path='/settings' element={<UserSettings />} />
          <Route path='/trial' element={<TrialCheck />} />
        </Route>

        <Route path='login' element={<LoginPage />}/>
      </Route>
    </Routes>
    <Toaster richColors/>
    </>
  );
}

export default App;
